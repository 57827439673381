import React, { useState, useEffect } from 'react';
import Sticky from 'react-stickynode';

import JuryGroup from './JuryGroup';
import withDragDropContext from '../global/withDragDropContext';
import JuryMemberItem from './DragJuryMemberItem';
import DraggableItem from '../global/DraggableItem';

const PopulateJuries = (props) => {
	const [juryMembers] = useState(null);
	const [juries, setJuries] = useState(null);

	useEffect(() => {
		setJuries(props.juries);
	}, [props.juries]);

	const onDrop = (id, jury) => {
		const { day, number } = jury;
		const leader_user_id = jury.leader.id;
		props.createJury({ day, number, leader_user_id, members: [id] });
	};

	const getJuryElements = (day) => {
		if (!juries || !juries[day - 1]) {
			return null;
		}
		let els = [];
		for (let i = 0; i < 4; i++) {
			let j = juries[day - 1] && juries[day - 1][i] ? juries[day - 1][i] : { day, number: i + 1, members: [] };

			els.push(<JuryGroup onDrop={onDrop} data={j} key={`${day - 1}-${i}`} />);
		}
		return els;
	};

	return (
		<div className="populate-juries with-drag">
			<h1>Jurymedlemmar</h1>
			<Sticky enabled={true} top={0} bottomBoundary={1200}>
				<div className="jury-members">
					{juryMembers &&
						juryMembers.map((jm, index) => {
							return (
								<div className="item" key={jm.id}>
									<JuryMemberItem index={index} data={jm} id={jm.id} />
								</div>
							);
						})}
				</div>
			</Sticky>
			<DraggableItem component={JuryMemberItem} />
			<div className="jury-days">
				<h1>Jurygrupper</h1>
				{juries &&
					juries.map((j, idx) => {
						return (
							<div className="day" key={idx + 1}>
								{getJuryElements(idx + 1)}
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default withDragDropContext(PopulateJuries);
