import axios from 'axios';

import { API_URL } from './constants';

axios.defaults.baseURL = API_URL;

const header = (user) => {
	return {
		Authorization: `Bearer ${user.accessToken}`,
		'X-Requested-By': user.unoId,
	};
};

export const get = (service, id, user) => {
	const headers = header(user);
	return axios({
		url: `/${service}/${id}`,
		method: 'get',
		headers,
	});
};

export const find = (service, query, user) => {
	let qs = '';
	if (query) {
		qs += '?';
		Object.keys(query).forEach((q) => {
			qs += `${q}=${query[q]}&`;
		});
		qs = qs.substring(0, qs.length - 1);
	}
	const headers = header(user);
	return axios({
		url: `/${service}${qs}`,
		method: 'get',
		headers,
	});
};

export const create = (service, params, user) => {
	const headers = header(user);
	const payload = {
		url: `/${service}`,
		method: 'post',
		data: params,
		headers,
	};
	return axios(payload);
};

export const patch = (service, id, params, user) => {
	const headers = header(user);
	return axios({
		url: `/${service}/${id}`,
		method: 'patch',
		data: params,
		headers,
	});
};

export const remove = (service, params, user) => {
	const headers = header(user);
	return axios({
		url: `/${service}`,
		method: 'delete',
		data: params,
		headers,
	});
};
