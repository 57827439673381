import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getSubmissions, batchGrade, batchStar } from '../../data/submission/submissionActions';
import {
	getPlaylists,
	createPlaylist,
	setPlaylistTrack,
	setSelectedItems,
	toggleFilter,
	removePlaylist,
} from '../../data/playlist/playlistActions';
import { populatePlaylists } from '../../data/playlist/playlistSelector';

import FilterSidebar from './FilterSidebar';
import SubmissionsRoundOne from './SubmissionsRoundOne';
import PlaylistsSidebar from './PlaylistsSidebar';

const CreatePlaylists = (props) => {
	useEffect(() => {
		props.getSubmissions();
		props.getPlaylists(1);
	}, []);

	const onDrop = (submissionId, playlistId, fromPlaylist) => {
		if (submissionId && playlistId) {
			props.setPlaylistTrack(
				{
					submissionId,
					playlistId,
				},
				'add'
			);
		}
		if (submissionId && !playlistId && fromPlaylist) {
			props.setPlaylistTrack(
				{
					submissionId,
					fromPlaylist,
				},
				'remove'
			);
		}
	};

	return (
		<div className="page create with-drag">
			<FilterSidebar toggleFilter={props.toggleFilter} filters={props.filters} />

			<SubmissionsRoundOne
				createPlaylist={props.createPlaylist}
				setPlaylistTrack={props.setPlaylistTrack}
				submissions={props.submissions}
				total={props.total}
				playlists={props.playlists}
				toggleFilter={props.toggleFilter}
				setSelectedItems={props.setSelectedItems}
				selectedItems={props.selectedItems}
				filters={props.filters}
				removePlaylist={props.removePlaylist}
				onDrop={onDrop}
				setGrades={props.setGrades}
				setStarred={props.setStarred}
			/>
			<PlaylistsSidebar
				removePlaylist={props.removePlaylist}
				createPlaylist={props.createPlaylist}
				playlists={props.playlists}
				onDrop={onDrop}
			/>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const [pl, sb] = populatePlaylists(state);
	return {
		total: state.submission.total,
		submissions: sb,
		playlists: pl,
		filters: state.playlist.filters,
		selectedItems: state.playlist.selectedItems || [],
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSubmissions: () => dispatch(getSubmissions()),
		getPlaylists: (round) => dispatch(getPlaylists(round)),
		createPlaylist: (data) => dispatch(createPlaylist(data)),
		removePlaylist: (id) => dispatch(removePlaylist(id)),
		setPlaylistTrack: (data, type) => dispatch(setPlaylistTrack(data, type)),
		toggleFilter: (type) => dispatch(toggleFilter(type)),
		setSelectedItems: (data) => dispatch(setSelectedItems(data)),
		setGrades: (ids, grade) => dispatch(batchGrade(ids, grade)),
		setStarred: (ids, starred) => dispatch(batchStar(ids, starred)),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePlaylists));
