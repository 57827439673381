import React, { useState, useEffect } from 'react';
import withDragDropContext from '../global/withDragDropContext';

import PlaylistItem from '../roundOne/DropPlaylistItem';

const PlaylistsSidebar = (props) => {
	const [playlists, setPlaylists] = useState(props.playlists);
	const [createVisible, setCreateVisible] = useState(false);
	const [newPlaylistName, setNewPlaylistName] = useState('');
	const [playlistOpen, setPlaylistOpen] = useState(false);

	const toggleCreate = () => {
		setCreateVisible(!createVisible);
	};

	const saveNewPlaylist = (e) => {
		e.preventDefault();
		props.createPlaylist({
			name: newPlaylistName,
			round: 3,
		});
		toggleCreate();
	};

	useEffect(() => {
		setPlaylists(props.playlists);
	}, [props.playlists]);

	return (
		<div className="sidebar playlists">
			<h1>Spellistor</h1>
			<div className="playlists-list">
				{Object.keys(playlists).map((p) => {
					return (
						<PlaylistItem
							data={playlists[p]}
							onOpen={setPlaylistOpen}
							onDrop={props.onDrop}
							disabled={playlistOpen && playlistOpen !== p}
							id={p}
							key={p}
							removePlaylist={props.removePlaylist}
						/>
					);
				})}
			</div>
			<div className="create-playlist">
				{!playlistOpen && !createVisible && (
					<button className="new-playlist small" onClick={toggleCreate}>
						Ny spellista
					</button>
				)}
				{createVisible && (
					<form onSubmit={saveNewPlaylist}>
						<div className="close icon-cancel" onClick={() => setCreateVisible(false)} />
						<h3>Ny spellista</h3>
						<div className="input">
							<label htmlFor="name">Namn:</label>
							<input
								type="text"
								name="name"
								value={newPlaylistName}
								onChange={(e) => {
									setNewPlaylistName(e.currentTarget.value);
								}}
								autoFocus
							/>
						</div>
						<button className="micro" onClick={saveNewPlaylist}>
							Spara
						</button>
					</form>
				)}
			</div>
		</div>
	);
};

export default withDragDropContext(PlaylistsSidebar);
