import { ON_SUBMISSIONS, UPDATE_SUBMISSIONS } from './submissionActions';
import { RESET_GRADE } from '../playlist/playlistActions';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

const initialState = {
	submissions: [],
	total: 0,
};

const submissionReducer = (state = initialState, action) => {
	switch (action.type) {
		case ON_SUBMISSIONS: {
			let ns = Object.assign({}, state, { submissions: action.data, total: action.data.length });
			return ns;
		}
		case RESET_GRADE: {
			const change = action.data.submissionId;
			const submissions = Object.assign([], state.submissions);
			change.forEach((d) => {
				let sub = find(submissions, (s) => {
					return s.id === d;
				});
				sub.grades = null;
				console.log({ sub });
			});
			let ns = Object.assign({}, state, { submissions });
			console.log('RESET_GRADE', ns);
			return ns;
		}
		case UPDATE_SUBMISSIONS: {
			let submissions = Object.assign([], state.submissions);
			action.data.forEach((d) => {
				let sub = findIndex(submissions, (s) => {
					return s.id === d.id;
				});
				if (sub > -1) {
					submissions[sub] = d;
				}
			});
			return Object.assign({}, state, { submissions });
		}
		default: {
			return state;
		}
	}
};

export default submissionReducer;
