import { find, create, remove } from '../api';
import { onGetUsers } from '../user/userActions';
import { onUpdateJury } from '../jury/juryActions';
import errorHandler from '../errorHandler';

export const ON_PLAYLISTS = 'ON_PLAYLISTS';
export const ON_PLAYLIST = 'ON_PLAYLIST';
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const SELECT_ITEMS = 'SELECT_ITEMS';
export const RESET_GRADE = 'RESET_GRADE';

export const toggleFilter = (data) => {
	return {
		type: TOGGLE_FILTER,
		data,
	};
};

export const setSelectedItems = (data) => {
	return {
		type: SELECT_ITEMS,
		data,
	};
};

const onPlaylists = (data) => {
	return {
		type: ON_PLAYLISTS,
		data,
	};
};

export const getPlaylists = (round) => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;
		find('playlist', { round }, user)
			.then((result) => {
				return dispatch(onPlaylists(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

const onPlaylist = (data) => {
	return {
		type: ON_PLAYLIST,
		data,
	};
};

export const createPlaylist = (data) => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;
		create('playlist', { ...data, ownerUserId: user.id }, user)
			.then((result) => {
				return dispatch(onPlaylist(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const removePlaylist = (id) => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;
		remove('playlist', { id }, user)
			.then((result) => {
				return dispatch(onPlaylists(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const setPlaylistTrack = (data, action, mode = null) => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;

		let plData = data;
		if (mode) {
			plData.mode = mode;
		}

		let fn = create;
		if (action === 'remove') {
			fn = remove;
		}
		dispatch({
			type: RESET_GRADE,
			data,
		});
		fn('playlist-submission', plData, user)
			.then((result) => {
				console.log('result', result.data.data);
				return mode ? dispatch(onUpdateJury(result.data.data)) : dispatch(onPlaylists(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const assignPlaylistToUser = (uid, pid, round) => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;
		create('assign-playlist', { jury_member_id: uid, playlist_id: pid, round }, user)
			.then((result) => {
				return dispatch(onGetUsers(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};
