import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';

import { filtered } from '../filters';

const getSubmissions = (state) => state.submission.submissions;
const getFilters = (state) => state.playlist.filters;

export const populateFinalPlaylist = createSelector([getSubmissions, getFilters], (submissions, filters) => {
	let sb = Object.assign([], submissions);
	sb = filter(sb, (s) => {
		return s.grade === 1 || s.starred === 1;
	});

	let pl = filter(sb, (s) => {
		return s.final === 1;
	});

	pl = orderBy(pl, ['finalOrder'], ['ASC']);
	const stats = {
		credits: {},
		genderRatio: 0,
		genres: {},
		languages: {},
	};

	let names = [];
	let genres = [];
	let languages = [];
	let genderRatio = 0;
	pl.forEach((p) => {
		sb.splice(sb.indexOf(p), 1);
		let credits = JSON.parse(p.credits);
		Object.keys(credits).forEach((t) => {
			credits[t].forEach((n) => {
				names.push(n.name);
			});
		});
		genderRatio += Number(p.gender_ratio);
		genres.push(p.genre);
		languages.push(p.language);
	});
	names.forEach((n) => {
		if (!stats.credits[n]) {
			stats.credits[n] = { name: n, songs: 0 };
		}
		stats.credits[n].songs++;
	});
	stats.credits = orderBy(stats.credits, ['songs'], ['desc']);
	stats.genderRatio = genderRatio / pl.length;

	genres.forEach((g) => {
		if (!stats.genres[g]) {
			stats.genres[g] = { genre: g, songs: 0 };
		}
		stats.genres[g].songs++;
	});
	stats.genres = orderBy(stats.genres, ['songs'], ['desc']);

	languages.forEach((l) => {
		if (!stats.languages[l]) {
			stats.languages[l] = { language: l, songs: 0 };
		}
		stats.languages[l].songs++;
	});
	stats.languages = orderBy(stats.languages, ['songs'], ['desc']);

	return [pl, filtered(sb, filters), stats];
});
