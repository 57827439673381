import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Top100Playlist from './Top100Playlist';
import CreateTop100Playlists from './CreateTop100Playlists';
import FinalPlaylist from './FinalPlaylist';

const routes = (props) => {
	if (props.mode === 'top100') {
		return (
			<Switch>
				<Route path="/home/r3/playlists" component={CreateTop100Playlists} />
				<Route path="/home/r3/top100" component={Top100Playlist} />
				<Redirect exact path="/home/r3/" to="/home/r3/playlists" />
			</Switch>
		);
	}
	return <FinalPlaylist />;
};

export default routes;
