import { find, create, patch } from '../api';
import errorHandler from '../errorHandler';

export const ON_SUBMISSIONS = 'ON_SUBMISSIONS';
export const UPDATE_SUBMISSIONS = 'UPDATE_SUBMISSIONS';

const onSubmissions = (data) => {
	return {
		type: ON_SUBMISSIONS,
		data,
	};
};

export const onUpdateSubmissions = (data) => {
	return {
		type: UPDATE_SUBMISSIONS,
		data,
	};
};

export const getSubmissions = (params = {}) => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;
		find('verified-submission', params, user)
			.then((result) => {
				return dispatch(onSubmissions(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const getPassedSubmissions = () => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;
		find('verified-submission/passed', {}, user)
			.then((result) => {
				return dispatch(onSubmissions(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const getTop100Submissions = () => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;
		find('verified-submission/top100', {}, user)
			.then((result) => {
				return dispatch(onSubmissions(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const getFinalSubmissions = () => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;
		find('verified-submission/final', {}, user)
			.then((result) => {
				return dispatch(onSubmissions(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const setTop100Track = (submissionId) => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;
		create('top100-submission', { submissionId }, user)
			.then((result) => {
				return dispatch(onSubmissions(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const setFinalTrack = (submissionId) => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;
		create('final-submission', { submissionId }, user)
			.then((result) => {
				return dispatch(onSubmissions(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const sortPlaylist = (data, mode) => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;
		let ids = [];
		data.forEach((d) => {
			ids.push(d.id);
		});
		patch(`${mode}-submission`, '', ids, user)
			.then((result) => {
				return dispatch(onSubmissions(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const batchGrade = (ids, value) => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;
		create('verified-submission/batch/grade', { ids, value }, user)
			.then((result) => {
				return dispatch(onUpdateSubmissions(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const batchStar = (ids, value) => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;
		create('verified-submission/batch/starred', { ids, value }, user)
			.then((result) => {
				return dispatch(onUpdateSubmissions(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};
