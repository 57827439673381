import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setPlayerProgress } from '../../data/app/appActions';
import { PLAYER_STATUS } from '../../data/constants';

const InvisibleAudioPlayer = (props) => {
	const { playerSong, playerStatus, playerPosition } = props;
	const player = useRef();

	const [playing, setPlaying] = useState(playerSong);
	//const [status, setStatus] = useState(playerStatus);

	const addListeners = useCallback((player) => {
		player.current.addEventListener('loadedmetadata', (e) => {
			props.setPlayerProgress({
				playerProgress: 0,
				playerCurrentTime: 0,
				playerSongLength: e.target.duration,
			});
		});
		player.current.addEventListener('timeupdate', (e) => {
			props.setPlayerProgress({
				playerProgress: Math.round((e.target.currentTime / e.target.duration) * 100),
				playerCurrentTime: e.target.currentTime,
				playerSongLength: e.target.duration,
			});
		});
	}, []);

	const removeListeners = useCallback((player) => {
		player.current.removeEventListener('loadedmetadata', (e) => {
			props.setPlayerProgress({
				playerProgress: 0,
				playerCurrentTime: 0,
				playerSongLength: e.target.duration,
			});
		});
		player.current.removeEventListener('timeupdate', (e) => {
			props.setPlayerProgress({
				playerProgress: Math.round((e.target.currentTime / e.target.duration) * 100),
				playerCurrentTime: e.target.currentTime,
				playerSongLength: e.target.duration,
			});
		});
	}, []);

	const playOnLoad = useCallback(() => {
		player.current.play();
	}, [playing]);

	const addMetaListener = useCallback(() => {
		player.current.addEventListener('loadedmetadata', playOnLoad);
	}, [playOnLoad]);
	const removeMetaListener = useCallback(() => {
		player.current.removeEventListener('loadedmetadata', playOnLoad);
	}, [playOnLoad]);

	useEffect(() => {
		addMetaListener();

		return () => {
			removeMetaListener();
		};
	}, [addMetaListener, removeMetaListener]);

	useEffect(() => {
		if (player.current && player.current.duration) {
			console.log('playerPosition', playerPosition * player.current.duration);
			player.current.currentTime = playerPosition * player.current.duration;
		}
	}, [playerPosition]);

	useEffect(() => {
		props.setPlayerProgress({ playerProgress: 0 });
		setPlaying(playerSong);
		addListeners(player);
		return () => {
			removeListeners(player);
		};
	}, [playerSong]);

	useEffect(() => {
		if (playing === playerSong && playerStatus === PLAYER_STATUS.PAUSED) {
			player.current.pause();
		} else if (playing === playerSong && playerStatus === PLAYER_STATUS.PLAYING) {
			player.current.play();
		}
	}, [playerStatus]);

	return <audio ref={player} src={playing && playing.audio} preload="auto" />;
};

const mapStateToProps = (state, ownProps) => {
	return {
		playerSong: state.app.playerSong,
		playerStatus: state.app.playerStatus,
		playerPosition: state.app.playerPosition,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPlayerProgress: (data) => dispatch(setPlayerProgress(data)),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvisibleAudioPlayer));
