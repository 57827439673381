import findIndex from 'lodash/findIndex';
import { ON_PLAYLISTS, ON_PLAYLIST, TOGGLE_FILTER, SELECT_ITEMS } from './playlistActions';

const initialState = {
	playlists: {},
	filters: [],
	selectedItems: [],
};

const playlistReducer = (state = initialState, action) => {
	switch (action.type) {
		case ON_PLAYLISTS: {
			let playlists = {};
			action.data.forEach((d) => {
				playlists[d.id] = d;
			});
			let ns = Object.assign({}, state, { playlists });
			console.log('ON_PLAYLISTS', ns);
			return ns;
		}
		case ON_PLAYLIST: {
			let pl = Object.assign([], state.playlists);
			pl[action.data.id] = action.data;
			let ns = Object.assign({}, state, { playlists: pl });
			console.log('ON_PLAYLIST', ns);
			return ns;
		}
		case TOGGLE_FILTER: {
			let filters = Object.assign([], state.filters);
			if (!action.data) {
				return Object.assign({}, state, { filters: [] });
			}
			let idx = findIndex(filters, (f) => {
				return f[0] === action.data[0];
			});

			if (idx > -1 && (parseInt(action.data[1], 10) === 0 || action.data[1] === filters[idx][1])) {
				filters.splice(idx, 1);
			} else if (idx > -1) {
				filters[idx] = action.data;
			} else {
				filters.push(action.data);
			}
			return Object.assign({}, state, { filters });
		}
		case SELECT_ITEMS: {
			return Object.assign({}, state, { selectedItems: action.data });
		}
		default: {
			return state;
		}
	}
};

export default playlistReducer;
