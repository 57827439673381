import { ON_JURIES, ON_JURY, ON_UPDATE_JURY } from './juryActions';

const initialState = {
	juries: [],
};

const juryReducer = (state = initialState, action) => {
	switch (action.type) {
		case ON_JURIES: {
			const ns = Object.assign({}, state, { juries: Object.assign([], action.data) });
			console.log('ON_JURIES', ns);
			return ns;
		}

		case ON_JURY: {
			const juries = Object.assign([], state.juries);
			juries[action.data.day - 1] = action.data.juries;
			const ns = Object.assign({}, state, { juries });
			console.log('ON_JURY', ns, action.data.day);
			return ns;
		}

		case ON_UPDATE_JURY: {
			const juries = Object.assign([], state.juries);

			juries.forEach((day) => {
				day.forEach((j) => {
					if (action.data.id) {
						if (j.id === action.data.id) {
							console.log('JURY', j.id, action.data.id);
							j.items = action.data.items;
						}
					} else {
						action.data.forEach((jj) => {
							if (j.id === jj.id) {
								j.items = jj.items;
							}
						});
					}
				});
			});

			const ns = Object.assign({}, state, { juries });
			console.log('ON_UPDATE_JURY', ns);
			return ns;
		}

		default: {
			return state;
		}
	}
};

export default juryReducer;
