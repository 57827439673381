import React, { useState, useEffect } from 'react';

import withDragDropContext from '../global/withDragDropContext';
import PlaylistItem from './DragPlaylistItem';
import PlaylistAssignmentItem from './PlaylistAssignmentItem';
import DraggableItem from '../global/DraggableItem';

const AssignToJuryMember = (props) => {
	const [playlists, setPlaylists] = useState([]);
	const [users, setUsers] = useState([]);

	function onDrop(playlistId, userId) {
		props.assignPlaylistToUser(userId, playlistId, 1);
	}

	useEffect(() => {
		setPlaylists(props.playlists);
	}, [props.playlists]);

	useEffect(() => {
		setUsers(
			props.users.filter((us) => {
				return us.level > 0 && us.level < 3;
			})
		);
	}, [props.users]);

	if (!playlists && !users) {
		return null;
	}

	return (
		<>
			<h1>Spellistor</h1>
			<div className="playlists">
				{playlists &&
					playlists.map((pl, index) => {
						return (
							<div className="item" key={pl.id}>
								<PlaylistItem index={index} data={pl} id={pl.id} />
							</div>
						);
					})}
				<DraggableItem component={PlaylistItem} />
			</div>
			<h1>Tilldela spellistor</h1>
			<div className="assignments">
				{users &&
					users.map((us, index) => {
						return (
							<div className="item" key={us.id}>
								<PlaylistAssignmentItem index={index} onDrop={onDrop} data={us} id={us.id} />
							</div>
						);
					})}
			</div>
		</>
	);
};

export default withDragDropContext(AssignToJuryMember);
