import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export default function(ComposedComponent) {
	class Authenticate extends React.Component {
		componentDidMount() {
			this.checkAndRedirect();
		}

		componentDidUpdate() {
			this.checkAndRedirect();
		}

		checkAndRedirect() {
			const { isAuthenticated, history } = this.props;
			if (!isAuthenticated && history.location.pathname.indexOf('signin') < 0) {
				history.push('/signin/');
			}
		}

		render() {
			return <>{this.props.isAuthenticated ? <ComposedComponent {...this.props} /> : null}</>;
		}
	}

	const mapStateToProps = (state) => {
		const user = state.user.user;
		return {
			isAuthenticated: user && user.id && user.name,
		};
	};

	const mapDispatchToProps = () => {
		return {};
	};

	return withRouter(
		connect(
			mapStateToProps,
			mapDispatchToProps
		)(Authenticate)
	);
}
