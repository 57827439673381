import React, { useState } from 'react';

const CreditsBlock = (props) => {
	const { data } = props;

	const [showingContact, setShowingContact] = useState(false);

	let { credits, contact } = data;
	credits = JSON.parse(credits);
	contact = JSON.parse(contact);

	const cEls = [];
	Object.keys(credits).forEach((c) => {
		const uEls = [];
		credits[c].forEach((u, i) => {
			console.log(i, credits[c].length);
			uEls.push(
				<span key={u.name}>
					{[
						<span key={`${u.name}-name`}>{u.name}</span>,
						<span key={`${u.name}-prev`}>
							{u.previousContestant ? <i className="icon-award" /> : null}
						</span>,
					]}
					{i === credits[c].length - 1 ? ' ' : ', '}
				</span>
			);
		});
		cEls.push(
			<span className="value" key={c} onClick={() => setShowingContact(true)}>
				<b>{c}</b> {uEls}
			</span>
		);
	});

	const coEls = [];
	contact.forEach((c) => {
		coEls.push(
			<div className="contact-item" key={c.name}>
				<div className="type">{c.type}</div>
				<div className="name">{c.name}</div>
				<div className="email">{c.email}</div>
				<div className="phone">{c.phone}</div>
			</div>
		);
	});
	const coEl = (
		<div className="contact-overlay">
			<div className="close" onClick={() => setShowingContact(false)}>
				<i className="icon-cancel" />
			</div>
			<h1>Kontakt</h1>
			{coEls}
		</div>
	);
	console.log({ showingContact });
	return (
		<div className="column credits">
			{cEls}
			{showingContact && <div className="contact">{coEl}</div>}
		</div>
	);
};

export default CreditsBlock;
