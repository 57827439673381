import React, { useState, useEffect } from 'react';
import find from 'lodash/find';

import 'react-virtualized/styles.css';

import withDragDropContext from '../global/withDragDropContext';
import SongList from './SongList';
import SongItem from './SongItem';
import SongListTop from '../global/SongListTop';

import { onSelectItem } from '../../data/filters';

const OrderSubmissions = React.memo((props) => {
	const { setSelectedItems, selectedItems, filters, toggleFilter, total, setGrades, setStarred } = props;

	const [submissions, setSubmissions] = useState(props.submissions || []);
	const [activeFilter, setActiveFilter] = useState(null);

	const [localSelectedItems, setLocalSelectedItems] = useState(selectedItems);

	function toggle(type, value) {
		toggleFilter([type, value]);
	}

	useEffect(() => {
		//	setSelectedItems([]);
		setSubmissions(props.submissions);
	}, [props.submissions]);

	useEffect(() => {
		let filter = find(filters, (f) => {
			return f[0] === 'sort';
		});
		if (filter) {
			return setActiveFilter(filter[1]);
		}
		return setActiveFilter(null);
	});

	useEffect(() => {
		setLocalSelectedItems(selectedItems);
	}, [selectedItems]);

	useEffect(() => {
		return () => {
			console.log('unmount');
			toggleFilter([]);
		};
	}, []);

	const onDrop = (a, b, c) => {
		setSelectedItems([]);
		props.onDrop(a, b, c);
	};

	const selectItem = (id, index, mode) => {
		setSelectedItems(onSelectItem(id, index, mode, submissions, selectedItems));
	};

	return (
		<>
			<div className="main">
				<div className="heading">
					<SongListTop
						headline={<h1>Skapa spellistor</h1>}
						submissions={submissions}
						total={total}
						selectedItems={selectedItems}
						selectItem={selectItem}
						setGrades={setGrades}
						setStarred={setStarred}
					/>
					<div className="list-header">
						<div className="status-play">
							<div
								className={'status' + (activeFilter && activeFilter === 'grade' ? ' active' : '')}
								onClick={(e) => toggle('sort', 'grade')}
							>
								Status
							</div>
							<div className="play" />
						</div>
						<div
							className={'title' + (activeFilter && activeFilter === 'title' ? ' active' : '')}
							onClick={(e) => toggle('sort', 'title')}
						>
							Titel
						</div>
						<div
							className={'artist' + (activeFilter && activeFilter === 'artistName' ? ' active' : '')}
							onClick={(e) => toggle('sort', 'artistName')}
						>
							Artist
						</div>
						<div className="info" />
						<div
							className={'playlist' + (activeFilter && activeFilter === 'playlist.name' ? ' active' : '')}
							onClick={(e) => toggle('sort', 'playlist.name')}
						>
							Spellista
						</div>
					</div>
				</div>

				<SongList
					submissions={submissions}
					selectedItems={selectedItems}
					onDrop={onDrop}
					selectItem={selectItem}
					component={SongItem}
				/>
			</div>
		</>
	);
});

export default withDragDropContext(OrderSubmissions);
