import {
	submitLoginPhone,
	submitValidatePhone,
	submitNewUser,
	requestUsers,
	submitDeleteUser,
	submitEditUser,
} from '../auth';
import errorHandler from '../errorHandler';

export const VERIFICATION_REQUESTED = 'VERIFICATION_REQUESTED';
export const ON_VERIFIED = 'ON_VERIFIED';
export const ON_GET_USERS = 'ON_GET_USERS';
export const LOG_OUT = 'LOG_OUT';

export const logOut = () => {
	return {
		type: LOG_OUT,
	};
};

const verificationRequested = (data) => {
	return {
		type: VERIFICATION_REQUESTED,
		data,
	};
};

const onVerified = (data) => {
	return {
		type: ON_VERIFIED,
		data,
	};
};

export const onGetUsers = (data) => {
	return {
		type: ON_GET_USERS,
		data,
	};
};

export const sendCode = (data) => {
	return async (dispatch, getCurrentState) => {
		const userData = {
			phone: getCurrentState().user.user.phone,
			code: data.code,
		};
		return submitValidatePhone(userData)
			.then((result) => {
				return dispatch(onVerified(result.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const signInPhone = (data) => {
	return async (dispatch, getCurrentState) => {
		if (!data) {
			return dispatch(logOut());
		}
		return submitLoginPhone(data)
			.then((result) => {
				return dispatch(verificationRequested(result.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const addUser = (data) => {
	return async (dispatch, getCurrentState) => {
		const action = data.id ? submitEditUser : submitNewUser;
		return action(data)
			.then((result) => {
				return dispatch(onGetUsers(result.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const deleteUser = (id) => {
	return async (dispatch) => {
		return submitDeleteUser(id)
			.then((result) => {
				return dispatch(onGetUsers(result.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};

export const getUsers = () => {
	return async (dispatch, getCurrentState) => {
		return requestUsers()
			.then((result) => {
				return dispatch(onGetUsers(result.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};
