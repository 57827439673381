import React, { useState, useEffect } from 'react';
import withDragDropContext from '../global/withDragDropContext';

import JuryItem from './DropJuryItem';

const JuriesSidebar = (props) => {
	const [playlists, setPlaylists] = useState(props.playlists);
	const [playlistOpen, setPlaylistOpen] = useState(false);

	useEffect(() => {
		setPlaylists(props.playlists);
	}, [props.playlists]);

	useEffect(() => {
		setPlaylistOpen(false);
	}, [props.day]);

	return (
		<div className="sidebar playlists">
			<h1>Jurygrupper</h1>
			<div className="juries-list">
				{Object.keys(playlists).map((p) => {
					return (
						<JuryItem
							data={playlists[p]}
							onOpen={setPlaylistOpen}
							onDrop={props.onDrop}
							disabled={playlistOpen && playlistOpen !== p}
							id={p}
							key={p}
							removePlaylist={props.removePlaylist}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default withDragDropContext(JuriesSidebar);
