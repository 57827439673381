/* eslint no-control-regex: 0 */

import React, { useState, useEffect } from 'react';

const SignInView = (props) => {
	if (props.redirect) {
		return null;
	}
	const [phone, setPhone] = useState('');
	const [done, setDone] = useState(false);

	function onSubmit(e) {
		if (done) {
			e.preventDefault();
			props.signInPhone({
				phone,
			});
		}
	}

	useEffect(() => {
		if (phone.length === 10) {
			setDone(true);
		} else {
			setDone(false);
		}
	}, [phone]);

	return (
		<div className="view centered sign-in">
			<h1>Logga in</h1>
			<form className="sign-in" onSubmit={onSubmit}>
				<div className="input">
					<label htmlFor="phone">Telefonnummer</label>
					<input
						type="phone"
						name="phone"
						onChange={(e) => {
							setPhone(e.currentTarget.value);
						}}
						placeholder="Ex. 0751234567"
						value={phone}
					/>
				</div>
				<div className="input">
					<label />
					<button onClick={onSubmit} disabled={done ? false : true}>
						Logga in
					</button>
				</div>
			</form>
		</div>
	);
};

export default SignInView;
