import React from 'react';
import { DragSource } from 'react-dnd';

const PlaylistItem = (props) => {
	const { data, disabled } = props;
	const { name, items } = data;
	const length = items.length + (items.length !== 1 ? ' låtar' : ' låt');

	if (disabled) {
		return null;
	}

	const { connectDragSource } = props;
	return connectDragSource(
		<div className={'playlist'}>
			<div className="playlist-header">
				<span className="name">{name}</span>
				<span className="length">{length}</span>
			</div>
		</div>
	);
};
export default DragSource(
	'PLAYLIST',
	{
		beginDrag: (props) => {
			return { item: props.data.id, data: props.data };
		},
	},
	(connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		clientOffset: monitor.getClientOffset(),
		isDragging: monitor.isDragging(),
	})
)(PlaylistItem);
