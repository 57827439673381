import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getPlaylists, assignPlaylistToUser } from '../../data/playlist/playlistActions';
import { assignedPlaylists } from '../../data/playlist/playlistAssignmentSelector';
import { getUsers } from '../../data/user/userActions';

import AssignToJuryMember from './AssignToJuryMember';

const AssignPlaylists = (props) => {
	useEffect(() => {
		props.getPlaylists(1);
		props.getUsers();
	}, []);

	return (
		<div className="page assign with-drag">
			<AssignToJuryMember
				users={props.users}
				assignPlaylistToUser={props.assignPlaylistToUser}
				playlists={props.playlists}
			/>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { playlists, users } = assignedPlaylists(state);
	return {
		playlists,
		users,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getPlaylists: (round) => dispatch(getPlaylists(round)),
		getUsers: () => dispatch(getUsers()),
		assignPlaylistToUser: (uid, pid, round) => dispatch(assignPlaylistToUser(uid, pid, round)),
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(AssignPlaylists)
);
