import React, { useState, useEffect, useImperativeHandle } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import CreditsBlock from '../global/CreditsBlock';
import ImageBlock from '../global/ImageBlock';
import { GENRES, LANGUAGES } from '../../data/constants';
import AudioPlayerControls from '../global/AudioPlayerControls';

const SongItem = React.forwardRef((props, ref) => {
	const { data, selected, index, virtualStyle, expanded, connectDragPreview } = props;
	/* const [dragging, setDragging] = useState(false); */
	const [itemSelected, setItemSelected] = useState(selected);

	useEffect(() => {
		if (connectDragPreview) {
			// Use empty image as a drag preview so browsers don't draw it
			// and we can draw whatever we want on the custom drag layer instead.
			connectDragPreview(getEmptyImage(), {
				// IE fallback: specify that we'd rather screenshot the node
				// when it already knows it's being dragged so we can hide it with CSS.
				captureDraggingState: true,
			});
		}
	}, []);

	function selectItem(e) {
		let mode = 'single';
		if (e.shiftKey) {
			mode = 'shift';
		}
		if (e.metaKey) {
			mode = 'cmd';
		}
		if (props.selectItem) {
			props.selectItem(data.id, index, mode);
		}
	}

	console.log({ data });

	useEffect(() => {
		setItemSelected(selected);
	}, [selected]);

	const { connectDragSource, connectDropTarget } = props;
	const elementRef = React.useRef(null);
	connectDragSource(elementRef);
	connectDropTarget(elementRef);
	useImperativeHandle(ref, () => ({
		getNode: () => elementRef.current,
	}));

	if (expanded) {
		let comment = [];
		if (data.comment) {
			comment.push(
				<div className="value" key="comment">
					<b>Kommentar</b> {data.comment}
				</div>
			);
		}

		const eStyle = Object.assign({}, virtualStyle, { height: 280, zIndex: 99 });

		return (
			<div className={'song-details' + (itemSelected ? ' selected' : '')} style={eStyle}>
				<div className="expanded-top">
					<div className="status">
						<div className={'status final with-grade' + (data.starred ? ' with-star' : '')}>
							<div className={'status-' + (data.finalGrade > 0 ? '1' : '1')}>
								{data.starred === 1 && <i className="icon-star-closed" />}
								<div className="big-grade" key="big-grade">
									{data.finalGrade}
								</div>
							</div>
						</div>
						<div className="download">
							<a href={data.audio} rel="noopener noreferrer" target="_blank">
								<i className="icon-download" />
							</a>
						</div>
					</div>

					<ImageBlock data={data} />

					<div className="details-top">
						<div className="title-artist">
							<h2>{data.title}</h2>
							<h3>{data.artistName}</h3>
						</div>
						<div className="close-details">
							<button className="nano" onClick={(e) => props.setExpanded(e, null)}>
								Stäng
							</button>
						</div>
						<AudioPlayerControls song={data} mode="full" />
					</div>
				</div>
				<div className="details-bottom">
					<div className="column">
						<div className="value">
							<b>Genre</b> {GENRES[data.genre]}
						</div>
						<div className="value">
							<b>Demo</b> {data.demo ? 'Ja' : 'Nej'}
						</div>
						<div className="value">
							<b>Språk</b> {LANGUAGES[data.language]}
						</div>
						<div className="value">
							<b>Könsfördelning</b> {data.gender_ratio}
						</div>

						<div className="value">
							<b>Uppladdare</b> {data.uploader}
						</div>
					</div>
					<CreditsBlock data={data} />
					<div className="column comment">
						{data.comment && (
							<>
								<b>Kommentar</b> {data.comment}
							</>
						)}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div
			ref={elementRef}
			className={'song-item' + (props.isDragging ? ' dragging' : '')}
			onClick={selectItem}
			style={virtualStyle}
		>
			<div className={'song-item-content' + (itemSelected ? ' selected' : '')}>
				<div className="status-play">
					<div className={'status final with-grade' + (data.starred ? ' with-star' : '')}>
						<div className={'status-' + (data.finalGrade > 0 ? '1' : '0')}>
							{data.starred === 1 && <i className="icon-star-closed" />}
							<div className="big-grade" key="big-grade">
								{data.finalGrade}
							</div>
						</div>
					</div>
					<AudioPlayerControls song={data} mode="single" />
				</div>
				<div className="title">{data.title}</div>
				<div className="artist">{data.artistName}</div>
				<div className="info">
					<button
						className="nano"
						onClick={(e) => {
							props.setExpanded(e, index);
						}}
					>
						Mer info
					</button>
				</div>
				<div className="playlist ellipsis">{data.playlist && data.playlist.name}</div>
			</div>
		</div>
	);
});

/* export default DragSource(
	'SONG',
	{
		beginDrag: (props) => {
			if (props.selection.length > 0) {
				return { items: props.selection, data: props.data, inPlaylist: props.inPlaylist };
			}
			return { items: [props.data.id], data: props.data, inPlaylist: props.inPlaylist };
		},
	},
	(connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		clientOffset: monitor.getClientOffset(),
		isDragging: monitor.isDragging(),
	})
)(SongItem); */

export default DropTarget(
	'SONG',
	{
		hover(props, monitor, component) {
			if (!component) {
				return null;
			}
			// node = HTML Div element from imperative API
			const node = component.getNode();
			if (!node) {
				return null;
			}

			const dragIndex = monitor.getItem().index;
			const hoverIndex = props.index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = node.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			//if (props.onMove) {
			if (props.onMove) {
				props.onMove(dragIndex, hoverIndex, props.data);

				// Note: we're mutating the monitor item here!
				// Generally it's better to avoid mutations,
				// but it's good here for the sake of performance
				// to avoid expensive index searches.
				monitor.getItem().index = hoverIndex;
				//}
			}
		},
	},
	(connect) => ({
		connectDropTarget: connect.dropTarget(),
	})
)(
	DragSource(
		'SONG',
		{
			beginDrag: (props) => {
				if (props.selection.length > 0) {
					return { items: props.selection, data: props.data, inPlaylist: props.inPlaylist };
				}
				return { items: [props.data.id], data: props.data, inPlaylist: props.inPlaylist };
			},
		},
		(connect, monitor) => ({
			connectDragSource: connect.dragSource(),
			isDragging: monitor.isDragging(),
		})
	)(SongItem)
);
