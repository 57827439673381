import React, { useState, useEffect } from 'react';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';

import 'react-virtualized/styles.css';

import withDragDropContext from '../global/withDragDropContext';
import SongList from './SongList';
import SongItem from './SongItem';

const OrderSubmissions = React.memo((props) => {
	const [submissions, setSubmissions] = useState(props.submissions || []);
	const [activeFilter, setActiveFilter] = useState(null);

	const [selectedItems, setSelectedItems] = useState([]);

	const { filters, toggleFilter, mode, playlists } = props;

	console.log({ playlists });

	function toggle(type, value) {
		toggleFilter([type, value]);
	}

	useEffect(() => {
		setSelectedItems([]);
		setSubmissions(props.submissions);
	}, [props.submissions]);

	useEffect(() => {
		let filter = find(filters, (f) => {
			return f[0] === 'sort';
		});
		if (filter) {
			return setActiveFilter(filter[1]);
		}
		return setActiveFilter(null);
	});

	const onDrop = (a, b, c) => {
		setSelectedItems([]);
		props.onDrop(a, b, c);
	};

	function selectItem(id, index, mode) {
		let sel = Object.assign([], selectedItems);
		switch (mode) {
			case 'single': {
				if (sel.indexOf(id) > -1) {
					sel = sel.length > 1 ? [id] : [];
				} else {
					sel = [id];
				}
				break;
			}
			case 'shift': {
				if (sel.length > 0) {
					let currentFirst = findIndex(submissions, (s) => {
						return s.id === sel[0];
					});
					let currentLast = findIndex(submissions, (s) => {
						return s.id === sel[sel.length - 1];
					});
					if (index < currentFirst) {
						for (let i = index; i < currentFirst; i++) {
							sel.push(submissions[i].id);
						}
					} else if (index > currentLast) {
						for (let i = currentLast + 1; i <= index; i++) {
							sel.push(submissions[i].id);
						}
					}
				} else {
					for (let i = 0; i <= index; i++) {
						sel.push(submissions[i].id);
					}
				}
				break;
			}
			case 'cmd': {
				if (sel.indexOf(id) > -1) {
					sel.splice(sel.indexOf(id), 1);
				} else {
					sel.push(id);
				}
				break;
			}
			default: {
				return false;
			}
		}

		setSelectedItems(sel);
	}

	let headline = 'Skapa Finallista';
	if (mode === 'top100') {
		headline = 'Skapa Top 100-lista';
	}

	return (
		<>
			<div className="main">
				<div className="heading">
					<h1>{headline}</h1>
					<span className="count">
						({submissions.length}/{props.total} låtar)
					</span>

					<div className="list-header">
						<div className="status-play">
							<div
								className={'status' + (activeFilter && activeFilter === 'grade' ? ' active' : '')}
								onClick={(e) => toggle('sort', 'grade')}
							>
								Betyg
							</div>
							<div className="play" />
						</div>
						<div
							className={'title' + (activeFilter && activeFilter === 'title' ? ' active' : '')}
							onClick={(e) => toggle('sort', 'title')}
						>
							Titel
						</div>
						<div
							className={'artist' + (activeFilter && activeFilter === 'artistName' ? ' active' : '')}
							onClick={(e) => toggle('sort', 'artistName')}
						>
							Artist
						</div>
						<div className="info" />
					</div>
				</div>

				<SongList
					submissions={submissions}
					playlists={playlists}
					selectedItems={selectedItems}
					onDrop={onDrop}
					onMove={props.onMove}
					selectItem={selectItem}
					component={SongItem}
					day={props.day}
				/>
			</div>
		</>
	);
});

export default withDragDropContext(OrderSubmissions);
