import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';

export const filtered = (sb, filters, juryDay) => {
	if (filters && filters.length > 0 && filters[0][0]) {
		let filtered = sb;
		filters.forEach((f) => {
			switch (f[0]) {
				case 'genre': {
					filtered = filter(filtered, (s) => {
						return s.genre === parseInt(f[1], 10);
					});
					break;
				}
				case 'language': {
					filtered = filter(filtered, (s) => {
						return s.language === f[1];
					});
					break;
				}
				case 'gender': {
					filtered = filter(filtered, (s) => {
						if (f[1] === 'o') {
							return s.artistGender === 'o';
						}
						return s.artistGender === f[1] || s.artistGender === 'o';
					});
					break;
				}
				case 'playlist': {
					filtered = filter(filtered, (s) => {
						return f[1] === 'ASSIGNED' ? s.playlist : !s.playlist;
					});
					break;
				}
				case 'passed': {
					filtered = filter(filtered, (s) => {
						if (f[1] === 'APPROVED') {
							return s.passed === 1;
						} else if (f[1] === 'REJECTED') {
							return s.passed === -1;
						} else {
							return s.passed === 0;
						}
					});
					break;
				}
				case 'grade': {
					filtered = filter(filtered, (s) => {
						if (f[1] === 'THUMBS_UP') {
							return s.grade === 1 || s.grade === 101;
						} else if (f[1] === 'THUMBS_DOWN') {
							return s.grade === -1 || s.grade === 99;
						} else {
							return s.grade === 0;
						}
					});
					break;
				}
				case 'starred': {
					filtered = filter(filtered, (s) => {
						if (f[1] === 'STAR') {
							return s.starred === 1;
						} else {
							return s.starred === 0;
						}
					});
					break;
				}
				case 'search': {
					filtered = filter(filtered, (s) => {
						const str = f[1];
						return (
							(s.title || '').toLowerCase().indexOf(str.toLowerCase()) > -1 ||
							(s.artistName || '').toLowerCase().indexOf(str.toLowerCase()) > -1 ||
							(s.credits || '').toLowerCase().indexOf(str.toLowerCase()) > -1 ||
							(s.contact || '').toLowerCase().indexOf(str.toLowerCase()) > -1 ||
							(s.uploader && s.uploader.toLowerCase().indexOf(str.toLowerCase()) > -1)
						);
					});
					break;
				}
				case 'sort': {
					console.log('sort', sb, filters, juryDay);
					if (f[1] === 'grade' && juryDay) {
						filtered = filtered.map((m) => {
							const bjg = find(m.grades, (fg) => {
								return fg.jury === 0 && fg.day === juryDay;
							});
							if (bjg) {
								m.tempGrade = bjg.grade;
							} else {
								m.tempGrade = 0;
							}
							return m;
						});
						filtered = orderBy(filtered, ['tempGrade', 'id'], ['desc', 'asc']);
					} else if (f[1] === 'grade') {
						filtered = orderBy(filtered, ['finalGrade', 'id'], ['desc', 'asc']);
					} else if (f[1] === 'playlist') {
						filtered = orderBy(filtered, ['playlists[0].number'], ['asc']);
					} else {
						filtered = sortBy(filtered, f[1]);
					}
					break;
				}
				default: {
					return;
				}
			}
		});
		return filtered;
	} else {
		return sb;
	}
};

export const onSelectItem = (id, index, mode, submissions, selectedItems) => {
	let sel = Object.assign([], selectedItems);
	console.log(id, index, mode);
	switch (mode) {
		case 'single': {
			if (sel.indexOf(id) > -1) {
				sel = sel.length > 1 ? [id] : [];
			} else {
				sel = [id];
			}
			break;
		}
		case 'shift': {
			if (sel.length > 0) {
				let currentFirst = findIndex(submissions, (s) => {
					return s.id === sel[0];
				});
				let currentLast = findIndex(submissions, (s) => {
					return s.id === sel[sel.length - 1];
				});
				if (index < currentFirst) {
					for (let i = index; i < currentFirst; i++) {
						sel.push(submissions[i].id);
					}
				} else if (index > currentLast) {
					for (let i = currentLast + 1; i <= index; i++) {
						sel.push(submissions[i].id);
					}
				}
			} else {
				for (let i = 0; i <= index; i++) {
					sel.push(submissions[i].id);
				}
			}
			break;
		}
		case 'cmd': {
			if (sel.indexOf(id) > -1) {
				sel.splice(sel.indexOf(id), 1);
			} else {
				sel.push(id);
			}
			break;
		}
		case 'all': {
			console.log(sel.length, submissions.length);
			if (sel.length > 0) {
				sel = [];
			} else {
				sel = submissions.map((s) => s.id);
			}
			break;
		}
		default: {
			return false;
		}
	}

	return sel;
};
