import React from 'react';
import { DropTarget } from 'react-dnd';

import JuryMemberItem from './DragJuryMemberItem';

const JuryGroup = (props) => {
	const { data } = props;
	let number = 1;
	let leader = { name: '' };
	let members = [];

	if (data) {
		number = data.number;
		leader = data.leader;
		members = data.members.map((jm, index) => {
			return (
				<div className="item" key={`${jm.id}-${jm.day}`}>
					<JuryMemberItem index={index} data={jm} id={jm.id} />
				</div>
			);
		});
	}

	if (!leader || !number) {
		return null;
	}

	const { connectDropTarget, isOver } = props;
	return connectDropTarget(
		<div className={'jury-group jury-' + number + (isOver ? ' over' : '')}>
			<div className="content">
				<div className="header">Jurygrupp {number}</div>
				<div className="leader">{leader.name}</div>
				{connectDropTarget(<div className="members">{members}</div>)}
			</div>
		</div>
	);
};

export default DropTarget(
	'JURYMEMBER',
	{
		drop(props, monitor) {
			props.onDrop(monitor.getItem().item, props.data);
		},
	},
	(connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
	})
)(JuryGroup);
