import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';

import { getFinalSubmissions, setFinalTrack, sortPlaylist } from '../../data/submission/submissionActions';
import { toggleFilter } from '../../data/playlist/playlistActions';
import { populateFinalPlaylist } from '../../data/jury/finalPlaylistSelector';

import FilterSidebar from '../roundOne/FilterSidebar';
import SubmissionsRoundThree from './SubmissionsRoundThree';
import PlaylistSidebar from './PlaylistSidebar';

const FinalPlaylist = (props) => {
	const [submissions, setSubmissions] = useState(props.submissions);
	const [playlist, setPlaylist] = useState(props.playlist);

	const day = parseInt(props.match.params.day, 10);
	useEffect(() => {
		props.getFinalSubmissions();
	}, []);

	useEffect(() => {
		setSubmissions(props.submissions);
	}, [props.submissions]);

	useEffect(() => {
		setPlaylist(props.playlist);
	}, [props.playlist]);

	const onDrop = (submissionId, pl, inPlaylist) => {
		if (pl && inPlaylist) {
			props.sortPlaylist(playlist, 'final');
		} else {
			props.setFinalTrack(submissionId);
		}
	};
	const onMove = (dragIndex, hoverIndex, item) => {
		if (isNaN(dragIndex) || isNaN(hoverIndex)) {
			return;
		}
		console.log(dragIndex, hoverIndex);
		let sub = playlist[dragIndex];
		let pl = Object.assign([], playlist);
		if (!sub) {
			sub = find(submissions, (s) => {
				return s.id === item.id;
			});
			console.log({ submissions }, sub);
		} else {
			pl.splice(dragIndex, 1);
		}
		pl.splice(hoverIndex, 0, sub);
		setPlaylist(pl);
		console.log(pl);
		/* const dragCard = cards[dragIndex]
			setCards(
				update(cards, {
					$splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
				}),
			) */
	};

	return (
		<div className="page final">
			<FilterSidebar toggleFilter={props.toggleFilter} mode="final" filters={props.filters} />

			<SubmissionsRoundThree
				mode="final"
				createPlaylist={props.createPlaylist}
				setFinalTrack={props.setFinalTrack}
				submissions={submissions}
				total={props.total}
				playlist={playlist}
				toggleFilter={props.toggleFilter}
				filters={props.filters}
				removePlaylist={props.removePlaylist}
				onDrop={onDrop}
				onMove={onMove}
				day={day}
			/>

			<PlaylistSidebar mode="final" playlist={playlist} stats={props.stats} onDrop={onDrop} onMove={onMove} />
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const [pl, sb, st] = populateFinalPlaylist(state);
	return {
		total: state.submission.total,
		submissions: sb,
		playlist: pl,
		stats: st,
		filters: state.playlist.filters,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getFinalSubmissions: (params) => dispatch(getFinalSubmissions(params)),
		setFinalTrack: (data, type) => dispatch(setFinalTrack(data, type, 'jury')),
		sortPlaylist: (data, mode) => dispatch(sortPlaylist(data, mode)),
		toggleFilter: (type) => dispatch(toggleFilter(type)),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinalPlaylist));
