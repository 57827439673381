import React from 'react';

const DeleteUserPrompt = (props) => {
	const { user, deleteUser, closePrompt } = props;

	const onClickDelete = () => {
		deleteUser(user.id);
		closePrompt();
	};

	return (
		<>
			<h3>Vill du radera {user.name}?</h3>
			<p>{user.name} kommer inte längre att kunna logga in.</p>
			<div className="controls">
				<button className="small warning" onClick={onClickDelete}>
					Ja, radera
				</button>
				<button className="small" onClick={closePrompt}>
					Nej, avbryt
				</button>
			</div>
		</>
	);
};

export default DeleteUserPrompt;
