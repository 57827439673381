import { createSelector } from 'reselect';
import find from 'lodash/find';

const getPlaylists = (state) => state.playlist.playlists;
const getUsers = (state) => state.user.users;

export const assignedPlaylists = createSelector(
	[getPlaylists, getUsers],
	(playlists, users) => {
		const plo = Object.assign({}, playlists);
		let pl = [];
		Object.keys(plo).forEach((o) => {
			pl.push(plo[o]);
		});
		const us = Object.assign([], users);

		if (!pl || pl.length === 0) {
			return { playlists: [], users: [] };
		}

		us.forEach((u) => {
			if (u.items && u.items.length > 0) {
				let plItems = [];
				u.items.forEach((i) => {
					let plItem = find(pl, (ppl) => {
						return ppl.id === i.id;
					});
					let index = pl.indexOf(plItem);
					plItems.push(Object.assign({}, plItem));
					pl.splice(index, 1);
				});
				u.items = plItems;
			}
		});
		console.log({ pl, us });
		return { playlists: pl, users: us };
	}
);
