import React, { useState, useEffect } from 'react';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { GENRES, LANGUAGES } from '../../data/constants';
import AudioPlayerControls from '../global/AudioPlayerControls';
import ImageBlock from '../global/ImageBlock';
import CreditsBlock from '../global/CreditsBlock';

const SongItem = React.memo((props) => {
	const { data, selected, index, virtualStyle, expanded, connectDragPreview } = props;
	/* const [dragging, setDragging] = useState(false); */
	const [itemSelected, setItemSelected] = useState(selected);

	let plName = '';
	if (data.playlist && data.playlist.name) {
		plName = data.playlist.name;
	}

	useEffect(() => {
		if (connectDragPreview) {
			// Use empty image as a drag preview so browsers don't draw it
			// and we can draw whatever we want on the custom drag layer instead.
			connectDragPreview(getEmptyImage(), {
				// IE fallback: specify that we'd rather screenshot the node
				// when it already knows it's being dragged so we can hide it with CSS.
				captureDraggingState: true,
			});
		}
	}, []);

	function selectItem(e) {
		let mode = 'single';
		if (e.shiftKey) {
			mode = 'shift';
		}
		if (e.metaKey) {
			mode = 'cmd';
		}
		props.selectItem(data.id, index, mode);
	}

	useEffect(() => {
		setItemSelected(selected);
	}, [selected]);

	if (expanded) {
		let comment = [];
		if (data.comment) {
			comment.push(
				<div className="value" key="comment">
					<b>Kommentar</b> {data.comment}
				</div>
			);
		}

		const eStyle = Object.assign({}, virtualStyle, { height: 280, zIndex: 99 });

		return (
			<div className={'song-details' + (itemSelected ? ' selected' : '')} style={eStyle}>
				<div className="expanded-top">
					<div className="status">
						<div className={'status-' + data.grade}>
							{data.starred === 1 && <i className="icon-star-closed" />}
						</div>
						<div className="download">
							<a href={data.audio} rel="noopener noreferrer" target="_blank">
								<i className="icon-download" />
							</a>
						</div>
					</div>
					<ImageBlock data={data} />

					<div className="details-top">
						<div className="title-artist">
							<h2>{data.title}</h2>
							<h3>{data.artistName}</h3>
						</div>
						<div className="close-details">
							<button className="nano" onClick={(e) => props.setExpanded(e, null)}>
								Stäng
							</button>
						</div>
						<AudioPlayerControls onPlay={selectItem} song={data} mode="full" />
					</div>
				</div>
				<div className="details-bottom">
					<div className="column">
						<div className="value">
							<b>Genre</b> {GENRES[data.genre]}
						</div>
						<div className="value">
							<b>Demo</b> {data.demo ? 'Ja' : 'Nej'}
						</div>
						<div className="value">
							<b>Språk</b> {LANGUAGES[data.language]}
						</div>
						<div className="value">
							<b>Könsfördelning</b> {data.gender_ratio}
						</div>

						<div className="value">
							<b>Uppladdare</b> {data.uploader}
						</div>
					</div>
					<CreditsBlock data={data} />
					<div className="column comment">
						{data.comment && (
							<>
								<b>Kommentar</b> {data.comment}
							</>
						)}
					</div>
				</div>
			</div>
		);
	}

	const { connectDragSource } = props;

	return connectDragSource(
		<div className="song-item" onClick={selectItem} style={virtualStyle}>
			<div className={'song-item-content' + (itemSelected ? ' selected' : '')}>
				<div className="status-play">
					<div className="status">
						<div className={'status-' + data.grade}>
							{data.starred === 1 && <i className="icon-star-closed" />}
						</div>
					</div>
					<AudioPlayerControls song={data} onPlay={selectItem} mode="single" />
				</div>
				<div className="title">{data.title}</div>
				<div className="artist">{data.artistName}</div>
				<div className="info">
					<button className="nano" onClick={(e) => props.setExpanded(e, index)}>
						Mer info
					</button>
				</div>
				<div className="playlist ellipsis">{plName}</div>
			</div>
		</div>
	);
});

export default DragSource(
	'SONG',
	{
		beginDrag: (props) => {
			if (props.selection.length > 0) {
				return { items: props.selection, data: props.data, inPlaylist: props.inPlaylist };
			}
			return { items: [props.data.id], data: props.data, inPlaylist: props.inPlaylist };
		},
	},
	(connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		clientOffset: monitor.getClientOffset(),
		isDragging: monitor.isDragging(),
	})
)(SongItem);
