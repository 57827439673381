import React from 'react';
import { DropTarget } from 'react-dnd';

import SongItem from './SongItem';

const Playlist = (props) => {
	const { playlist } = props;
	let length = playlist.length;

	const { connectDropTarget } = props;
	return connectDropTarget(
		<div className="songs">
			{playlist.map((item, index) => (
				<SongItem
					selection={[]}
					data={item}
					index={index}
					inPlaylist={true}
					onMove={props.onMove}
					key={item.id}
				/>
			))}
			{length === 0 && <div className="empty-playlist">Dra och släpp låtar här</div>}
		</div>
	);
};
export default DropTarget(
	'SONG',
	{
		drop(props, monitor, component) {
			props.onDrop(monitor.getItem().items, 'final', monitor.getItem().inPlaylist);
		},
	},
	(connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
	})
)(Playlist);
