import React from 'react';

const PromptView = (props) => {
	if (!props.component) {
		return null;
	}
	const SubView = React.cloneElement(props.component, {
		...props,
	});
	return (
		<div className="prompt">
			<div className="prompt-content">
				<div className="close" onClick={props.closePrompt} />
				{SubView}
			</div>
		</div>
	);
};

export default PromptView;
