import React, { useState } from 'react';
import { DropTarget } from 'react-dnd';

import SongItem from './SongItem';

const PlaylistItem = (props) => {
	const { data, id, disabled /* , onOpen */ } = props;
	const { name, items } = data;
	const length = items.length + (items.length !== 1 ? ' låtar' : ' låt');
	const [expanded, setExpanded] = useState(false);

	function expand() {
		if (expanded) {
			props.onOpen(false);
		} else {
			props.onOpen(id);
		}
		setExpanded(!expanded);
	}

	if (disabled) {
		return null;
	}

	function removePlaylist() {
		props.removePlaylist(id);
	}

	const { connectDropTarget } = props;
	return connectDropTarget(
		<div className={'playlist' + (expanded ? ' expanded' : '')} onClick={expand}>
			<div className="playlist-header">
				<span className="name">{name}</span>
				<span className="length">{length}</span>
				{expanded && <div className="close icon-cancel" onClick={expand} />}
			</div>

			{expanded && (
				<>
					<div className="songs">
						{items.map((item, index) => (
							<SongItem inPlaylist={data.id} selection={[]} data={item} key={item.id} />
						))}
					</div>
					<div className="footer">
						<button className="micro warning" onClick={removePlaylist}>
							Ta bort spellista
						</button>
					</div>
				</>
			)}
			{expanded && items.length === 0 && <div className="empty-playlist">Dra och släpp låtar här</div>}
		</div>
	);
};
export default DropTarget(
	'SONG',
	{
		drop(props, monitor) {
			props.onDrop(monitor.getItem().items, props.data.id);
		},
	},
	(connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
	})
)(PlaylistItem);
