import React, { useState } from 'react';
import { DropTarget } from 'react-dnd';
import find from 'lodash/find';

import SongItem from './SongItem';

const JuryItem = (props) => {
	const { data, id, disabled /* , onOpen */ } = props;
	const { number, members, leader, items } = data;
	let length = items.length;
	const lengthString = length + (items.length !== 1 ? ' låtar' : ' låt');
	const [expanded, setExpanded] = useState(false);

	function expand() {
		if (expanded) {
			props.onOpen(false);
		} else {
			props.onOpen(id);
		}
		setExpanded(!expanded);
	}

	if (disabled) {
		return null;
	}

	let gradeUp = 0;
	let gradeDown = 0;
	data.items.forEach((i) => {
		if (i.grades) {
			let grades = i.grades;
			let juryGrade = find(grades, (g) => {
				return g.day === data.day && g.jury === data.number;
			});
			if (juryGrade) {
				if (juryGrade.grade === 1) {
					gradeUp++;
					length--;
				} else if (juryGrade.grade === -1) {
					gradeDown++;
					length--;
				}
			}
		}
	});

	const { connectDropTarget } = props;
	return connectDropTarget(
		<div className={`jury-item jury-${number} ${expanded ? ' expanded' : ''}`} onClick={expand}>
			<div className="content">
				<div className="jury-stats">
					<div className="stats">
						<div className="stat">
							<div className="icon">
								<i className="icon-thumbsup-closed" />
							</div>
							<div className="digit">{gradeUp}</div>
						</div>
						<div className="stat">
							<div className="icon">
								<i className="dot" />
							</div>
							<div className="digit">{length}</div>
						</div>
						<div className="stat">
							<div className="icon">
								<i className="icon-thumbsdown-closed" />
							</div>
							<div className="digit">{gradeDown}</div>
						</div>
					</div>
				</div>
				<div className="jury-info">
					<span className="name">Jurygrupp {number}</span>
					<span className="length">{lengthString}</span>
					<div className="members">
						<div className="jury-leader">{leader.name}</div>
						{members.map((m) => {
							return (
								<div className="member" key={m.id}>
									{m.name}
								</div>
							);
						})}
					</div>
					{expanded && <div className="close icon-cancel" onClick={expand} />}
				</div>
				{expanded && (
					<div className="songs">
						{items.map((item, index) => (
							<SongItem
								inPlaylist={data.id}
								mode="jury-playlist"
								selection={[]}
								data={item}
								key={item.id}
								jury={data}
							/>
						))}
					</div>
				)}
				{expanded && items.length === 0 && <div className="empty-playlist">Dra och släpp låtar här</div>}
			</div>
		</div>
	);
};
export default DropTarget(
	'SONG',
	{
		drop(props, monitor) {
			props.onDrop(monitor.getItem().items, props.data.id);
		},
	},
	(connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
	})
)(JuryItem);
