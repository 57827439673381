import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import HeaderView from './HeaderView';

import { logOut } from '../../../data/user/userActions';

const mapStateToProps = (state) => {
	return {
		user: state.user.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logOut: () => dispatch(logOut()),
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(HeaderView)
);
