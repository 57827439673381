import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Route, Redirect, Switch } from 'react-router-dom';

import withAuthentication from '../global/withAuthentication';

import Header from '../global/header/Header';
import RoundOne from '../roundOne';
import RoundTwo from '../roundTwo';
import RoundThree from '../roundThree';
import Users from '../users/Users';
import InvisibleAudioPlayer from '../global/InvisibleAudioPlayer';

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		/* 
		getUser: (id) => dispatch(getUser(id)), */
	};
};

const routes = (props) => {
	return (
		<>
			<Header />
			<Switch>
				<Route path="/home/r1" component={RoundOne} />
				<Route path="/home/r2" component={RoundTwo} />
				<Route path="/home/r3" render={() => <RoundThree mode="top100" />} />
				<Route path="/home/r4" render={() => <RoundThree mode="final" />} />
				<Route path="/home/users" component={Users} />

				<Redirect exact path="/home/" to="/home/r1" />
			</Switch>
			<InvisibleAudioPlayer />
		</>
	);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAuthentication(routes)));
