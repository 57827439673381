import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const HeaderView = (props) => {
	const [navigation, setNavigation] = useState(null);

	useEffect(() => {
		if (props.location.pathname.indexOf('/r1') > -1) {
			setNavigation('r1');
		} else if (props.location.pathname.indexOf('/r2') > -1) {
			setNavigation('r2');
		} else if (props.location.pathname.indexOf('/r3') > -1) {
			setNavigation('r3');
		} else if (props.location.pathname.indexOf('/users') > -1) {
			setNavigation('users');
		}
	}, [props.location.pathname]);

	function logOut() {
		props.logOut();
		props.history.push('/');
	}

	return (
		<header>
			<div className="logo">
				<figure>
					<img src="/images/logo.png" alt="Melodifestivalen" />
				</figure>
			</div>
			<div className="navigation">
				<div className="main-navigation">
					<NavLink to="/home/r1" className="round-header">
						Urval 1
					</NavLink>
					<NavLink to="/home/r2" className="round-header">
						Urval 2
					</NavLink>
					<NavLink to="/home/r3" className="round-header">
						Topp 100
					</NavLink>
					<NavLink to="/home/r4" className="round-header">
						Final
					</NavLink>
					<NavLink to="/home/users" className="round-header">
						Användare
					</NavLink>
				</div>

				{navigation === 'r1' && (
					<div className="sub-navigation">
						<NavLink to="/home/r1/create">Skapa spellistor</NavLink>
						<NavLink to="/home/r1/assign">Tilldela spellistor</NavLink>
					</div>
				)}
				{navigation === 'r2' && (
					<div className="sub-navigation">
						<NavLink to="/home/r2/1">Spellistor</NavLink>
						<NavLink to="/home/r2/juries">Jurygrupper</NavLink>
					</div>
				)}
				{navigation === 'r3' && (
					<div className="sub-navigation">
						<NavLink to="/home/r3/playlists">Spellistor</NavLink>
						<NavLink to="/home/r3/top100">Topp 100</NavLink>
					</div>
				)}

				{navigation === 'users' && (
					<div className="sub-navigation">
						<NavLink to="/home/r3/juries" />
					</div>
				)}
			</div>
			<div className="user">
				<div className="name-phone">
					<div className="name">{props.user.name}</div>
					<div className="phone">+{props.user.phone}</div>
					<div className="controls">
						<button className="micro purple" onClick={logOut}>
							Logga ut
						</button>
					</div>
				</div>
			</div>
		</header>
	);
};

export default HeaderView;
