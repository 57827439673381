import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import PromptView from './PromptView';

import { togglePrompt } from '../../../data/app/appActions';

const mapStateToProps = (state) => {
	return {
		component: state.app.prompt,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closePrompt: () => dispatch(togglePrompt()),
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(PromptView)
);
