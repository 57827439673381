import React, { useEffect } from 'react';
import find from 'lodash/find';
import { GENRES, LANGUAGES, GENDERS } from '../../data/constants';

const FilterSidebar = (props) => {
	const { filters, toggleFilter, mode } = props;

	function toggle(type, value) {
		toggleFilter([type, value]);
	}

	function active(type, value) {
		const isActive = find(filters, (f) => {
			return f[0] === type && f[1] === value;
		});
		return isActive ? ' active' : '';
	}

	useEffect(() => {
		console.log({ filters });
		return () => toggleFilter(null);
	}, []);

	return (
		<div className="sidebar filter">
			<section className="genre-language">
				<select name="genre" onChange={(e) => toggle('genre', e.currentTarget.value)}>
					<option value={0}>Genre</option>
					{Object.keys(GENRES).map((g) => {
						return (
							<option value={g} key={g}>
								{GENRES[g]}
							</option>
						);
					})}
				</select>
				<select name="language" onChange={(e) => toggle('language', e.currentTarget.value)}>
					<option value={0}>Språk</option>
					{Object.keys(LANGUAGES).map((l) => {
						return (
							<option value={l} key={l}>
								{LANGUAGES[l]}
							</option>
						);
					})}
				</select>
				<select name="gender" onChange={(e) => toggle('gender', e.currentTarget.value)}>
					<option value={0}>Artistens kön</option>
					{Object.keys(GENDERS).map((g) => {
						return (
							<option value={g} key={g}>
								{GENDERS[g]}
							</option>
						);
					})}
				</select>
			</section>
			{mode !== 'final' && (
				<section className="assigned-unassigned">
					<div className="headline">Filtera efter tilldelning</div>
					<button
						className={'filter ' + active('playlist', 'ASSIGNED')}
						onClick={() => toggle('playlist', 'ASSIGNED')}
					>
						Tilldelade
					</button>
					<button
						className={'filter ' + active('playlist', 'UNASSIGNED')}
						onClick={() => toggle('playlist', 'UNASSIGNED')}
					>
						Ej tilldelade
					</button>
				</section>
			)}
			<section className="status">
				<div className="headline">Filtera efter betyg</div>
				<button className={'filter ' + active('starred', 'STAR')} onClick={() => toggle('starred', 'STAR')}>
					<i className="icon-star-open" />
				</button>
				<button
					className={'filter ' + active('grade', 'THUMBS_UP')}
					onClick={() => toggle('grade', 'THUMBS_UP')}
				>
					<i className="icon-thumbsup-open" />
				</button>
				<button
					className={'filter ' + active('grade', 'THUMBS_DOWN')}
					onClick={() => toggle('grade', 'THUMBS_DOWN')}
				>
					<i className="icon-thumbsdown-open" />
				</button>
				<button className={'filter ' + active('grade', 'NO_GRADE')} onClick={() => toggle('grade', 'NO_GRADE')}>
					<i className="icon-circle-open" />
				</button>
			</section>
			<section className="search">
				<div className="headline">Sök</div>
				<input type="text" onChange={(e) => toggle('search', e.currentTarget.value)} />
			</section>
		</div>
	);
};

export default FilterSidebar;
