import React from 'react';
import withDragDropContext from '../global/withDragDropContext';

import Playlist from './DropPlaylist';
import PlaylistStats from './PlaylistStats';

const PlaylistSidebar = (props) => {
	const { playlist, mode } = props;
	let length = playlist.length;
	length += length === 1 ? ' låt' : ' låtar';

	let headline = 'Final Spellista';
	if (mode === 'top100') {
		headline = 'Topp 100 Spellista';
	}

	return (
		<div className="sidebar final-playlist">
			<div className="playlist-header">
				<span className="name">
					<h1>{headline}</h1>
				</span>
				<span className="length">{length}</span>
			</div>
			<Playlist {...props} />
			<PlaylistStats {...props} />
		</div>
	);
};

export default withDragDropContext(PlaylistSidebar);
