import SignInView from './SignInView';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { signInPhone } from '../../data/user/userActions';

const mapStateToProps = (state, ownProps) => {
	const user = state.user.user;
	if (user && user.phone && !user.id) {
		setTimeout(() => {
			ownProps.history.push('/signin/code/');
		}, 10);
		return {
			redirect: true,
		};
	}

	if (user && user.phone && user.id) {
		setTimeout(() => {
			ownProps.history.push('/home/');
		}, 10);
		return {
			redirect: true,
		};
	}

	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		signInPhone: (data) => dispatch(signInPhone(data)),
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SignInView)
);
