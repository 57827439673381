import { API_URL } from './constants';

export const submitLoginPhone = (data) => {
	return post(`${API_URL}/jury-member/`, data);
};

export const submitValidatePhone = (data) => {
	return post(`${API_URL}/jury-member/code/`, data);
};

export const submitNewUser = (data) => {
	return post(`${API_URL}/jury-member/add/`, data);
};

export const requestUsers = () => {
	return get(`${API_URL}/jury-member/`);
};

export const submitDeleteUser = (id) => {
	return post(`${API_URL}/jury-member/delete/`, { id });
};

export const submitEditUser = (data) => {
	return post(`${API_URL}/jury-member/edit/`, data);
};

const post = (endPoint, data) => {
	const request = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	};
	return requestJson(endPoint, request, post);
};

const get = (endPoint, auth = 'Basic TWVsb2RpZmVzdGl2YWxlbjpwYXNzd29yZA==') => {
	const request = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: auth,
		},
	};
	return requestJson(endPoint, request, get);
};

const requestJson = (endPoint, headers, action = null) => {
	return fetch(endPoint, headers)
		.then((result) => {
			if (result.status >= 400) {
				throw new Error(result._bodyText);
			}
			return result.json();
		})
		.then((result) => {
			if (result.type && result.status >= 400) {
				return Promise.reject(result);
			}
			return result;
		})
		.catch((error) => {
			return error;
		});
};
