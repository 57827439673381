import { createSelector } from 'reselect';
import find from 'lodash/find';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';

import { filtered } from '../filters';

const getJuries = (state) => state.jury.juries;
const getSubmissions = (state) => state.submission.submissions;
const getFilters = (state) => state.playlist.filters;
const getJuryDay = (state) => state.app.juryDay;

export const populateJuryPlaylists = createSelector(
	[getJuries, getSubmissions, getFilters, getJuryDay],
	(allJuries, submissions, filters, juryDay) => {
		const pl = {};
		let sb = Object.assign([], submissions);
		sb = filter(sb, (s) => {
			return s.grade === 1 || s.starred === 1;
		});
		if (!allJuries || !juryDay || juryDay === 0 || !allJuries[juryDay - 1]) {
			return [pl, sb];
		}
		allJuries.forEach((aj) => {
			aj.forEach((p) => {
				let pp = Object.assign({}, p);
				let items = [];

				pp.items.forEach((i) => {
					let sub = find(sb, (s) => {
						return s.id === i;
					});
					if (sub) {
						if (!sub.playlists) {
							sub.playlists = [];
						}
						sub.playlists[pp.day - 1] = { id: pp.id, number: pp.number, day: pp.day };
						if (sub.grades && typeof sub.grades === 'string') {
							console.log('sub', sub.grades);
							sub.grades = JSON.parse(sub.grades);
						}
						items.push(sub);
					}
				});

				pp.items = items;
				if (pp.day === juryDay) {
					pl[pp.id] = pp;
				}

				const ppLength = pp.items.length;
				const sbScan = filter(sb, (s) => {
					return (
						s.playlists &&
						find(s.playlists, (sp) => {
							return sp && sp.id === pp.id;
						})
					);
				});
				if (ppLength !== sbScan.length) {
					sbScan.forEach((s) => {
						if (
							!pp.items.find((i) => {
								return i.id === s.id;
							})
						) {
							const idx = findIndex(s.playlists, (sp) => {
								return sp && sp.id === pp.id;
							});
							s.playlists.splice(idx, 1);
						}
					});
				}
			});
		});

		return [pl, filtered(sb, filters, juryDay)];
	}
);
