import React from 'react';

const ImageBlock = (props) => {
	const { data } = props;
	let { image } = data;

	if (!image) {
		image = 'https://svt-veto-files.s3-eu-west-1.amazonaws.com/default-image.png';
	}

	return (
		<a href={image} target="_blank" rel="noopener noreferrer">
			<div className="image" style={{ backgroundImage: `url(${image})` }} />
		</a>
	);
};

export default ImageBlock;
