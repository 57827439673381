import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import createStore from './data/store';

import Root from './components/root/Root';
import SignIn from './components/signIn/';
import Prompt from './components/global/prompt/Prompt';

const { store, persistor } = createStore();

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Router>
						<div className="container">
							<Switch>
								<Route path="/home/" component={Root} />
								<Route path="/signin/" component={SignIn} />
								<Redirect exact path="/" to="/signin" />
							</Switch>
							<Prompt />
						</div>
					</Router>
				</PersistGate>
			</Provider>
		);
	}
}

export default App;
