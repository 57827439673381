import { VERIFICATION_REQUESTED, ON_VERIFIED, ON_GET_USERS } from './userActions';

const initialState = {
	isAuthenticated: false,
	user: {
		id: null,
		name: null,
		phone: null,
		level: 3,
	},
	users: [],
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case VERIFICATION_REQUESTED: {
			let user = Object.assign({}, state.user);
			user.phone = action.data.phone;
			let ns = Object.assign({}, state, { user });
			return ns;
		}
		case ON_VERIFIED: {
			let user = Object.assign({}, state.user);
			user.name = action.data.name;
			user.id = action.data.id;
			user.level = action.data.level;

			let ns = Object.assign({}, state, { user, isAuthenticated: true });
			return ns;
		}
		case ON_GET_USERS: {
			let users = action.data;
			let ns = Object.assign({}, state, { users });
			return ns;
		}

		default: {
			return state;
		}
	}
};

export default userReducer;
