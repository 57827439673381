import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import DayPlaylists from './DayPlaylists';
import Juries from './Juries';

const routes = (props) => {
	return (
		<Switch>
			<Route exact path="/home/r2/juries" component={Juries} />
			<Route path="/home/r2/:day" component={DayPlaylists} />
			<Redirect exact path="/home/r2/" to="/home/r2/1" />
		</Switch>
	);
};

export default routes;
