export const GENRES = {
	1: 'Pop',
	2: 'Hip hop',
	3: 'Ballad',
	4: "Soul/R'n'B",
	5: 'House/Techno',
	6: 'Country',
	7: 'Rock',
	8: 'Etno/Folk',
	9: 'Schlager/Dansband',
	10: 'Other',
};

export const LANGUAGES = {
	sv: 'Svenska',
	en: 'Engelska',
	oo: 'annat',
};

export const GENDERS = {
	f: 'Kvinna',
	m: 'Man',
	o: 'Kvinna & Man',
};

export const STATUSES = {
	10: 'submitted',
};

export const PLAYER_STATUS = {
	IDLE: 'idle',
	LOADING: 'loading',
	PLAYING: 'playing',
	PAUSED: 'paused',
	ENDED: 'ended',
};

export const GENRE_COLORS = {
	1: '#FFA000',
	2: '#D70A8C',
	3: '#F050B6',
	4: '#FA5A28',
	5: '#0AAAE1',
	6: '#9579DA',
	7: '#02CB6B',
	8: '#79DAA9',
	9: '#3D3D3D',
	10: '#ebebeb',
};

const apiUrl = () => {
	let url = 'http://localhost:5001';
	//	url = 'https://d2bki5l3kotv7y.cloudfront.net';
	if (
		window.location.href.indexOf('melodifestivalen.se') > -1 ||
		window.location.href.indexOf('amazonaws.com') > -1
	) {
		url = 'https://d2bki5l3kotv7y.cloudfront.net';
	} else if (window.location.href.indexOf('iamfoolish.com') > -1) {
		url = 'http://api.veto.iamfoolish.com';
	}
	return url;
};

const unoParams = () => {
	let unoUrl = 'https://d1xhtdo00aava9.cloudfront.net/authentication/v3';
	let unoAuth = 'Basic VmV0bzphYg==';
	if (
		window.location.href.indexOf('melodifestivalen.se') > -1 ||
		window.location.href.indexOf('amazonaws.com') > -1
	) {
		unoUrl = 'https://api.svt.se/authentication/v3';
		unoAuth = 'Basic VmV0bzp0ZXN0';
	}
	return { unoUrl, unoAuth };
};

export const API_URL = apiUrl();
export const UNO_PARAMS = unoParams();
