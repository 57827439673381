import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import CreatePlaylists from './CreatePlaylists';
import AssignPlaylists from './AssignPlaylists';

const routes = (props) => {
	return (
		<Switch>
			<Route path="/home/r1/create" component={CreatePlaylists} />
			<Route path="/home/r1/assign" component={AssignPlaylists} />
			<Redirect exact path="/home/r1/" to="/home/r1/create" />
		</Switch>
	);
};

export default routes;
