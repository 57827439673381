import React from 'react';
import { DropTarget } from 'react-dnd';

import PlaylistItem from './DragPlaylistItem';

const PlaylistAssignmentItem = (props) => {
	const { data, disabled } = props;
	const { name, items } = data;

	if (!items || disabled) {
		return null;
	}

	let length = items.length;
	length += length === 1 ? ' lista' : ' listor';

	const { connectDropTarget, isOver } = props;
	return connectDropTarget(
		<div className={'playlist-assigment-item' + (isOver ? ' over' : '')}>
			<div className="content">
				<div className="pa-header">
					<div className="name">{name}</div>
					<div className="length">{length}</div>
				</div>
				<div className="pa-items">
					<div className="playlists">
						{items.map((item, index) => (
							<PlaylistItem selection={[]} data={item} key={item.id} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
export default DropTarget(
	'PLAYLIST',
	{
		drop(props, monitor) {
			props.onDrop(monitor.getItem().item, props.data.id);
		},
	},
	(connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
	})
)(PlaylistAssignmentItem);
