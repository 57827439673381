import { TOGGLE_PROMPT, SET_PLAYER_STATUS, SET_PLAYER_PROGRESS, SET_PLAYER_POSITION, SET_JURY_DAY } from './appActions';
import { PLAYER_STATUS } from '../constants';

const initialState = {
	prompt: null,
	playerSong: null,
	playerStatus: PLAYER_STATUS.IDLE,
	playerProgress: 0,
	playerCurrentTime: 0,
	playerSongLength: 0,
	playerPosition: null,
	juryDay: 0,
};

const appReducer = (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_PROMPT: {
			let ns = Object.assign({}, state, { prompt: null });
			if (action.component) {
				ns.prompt = action.component;
			}
			return ns;
		}

		case SET_PLAYER_STATUS: {
			let ns = Object.assign({}, state);
			if (state.playerSong !== action.data.playerSong) {
				ns.playerSong = action.data.playerSong;
			}
			ns.playerStatus = action.data.playerStatus;
			return ns;
		}

		case SET_PLAYER_PROGRESS: {
			return Object.assign({}, state, { ...action.data });
		}

		case SET_PLAYER_POSITION: {
			const ns = Object.assign({}, state, { playerPosition: action.data });
			console.log('SET_PLAYER_POSITION', ns);
			return ns;
		}

		case SET_JURY_DAY: {
			return Object.assign({}, state, { juryDay: action.data });
		}

		default: {
			return state;
		}
	}
};

export default appReducer;
