import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setPlayerStatus, setPlayerPosition } from '../../data/app/appActions';
import { PLAYER_STATUS } from '../../data/constants';

const AudioPlayerControls = (props) => {
	const { song, playerStatus, playerSong, playerProgress, playerCurrentTime, playerSongLength, mode, onPlay } = props;

	const stubPlayer = React.useRef();

	const onClick = (e) => {
		e.stopPropagation();
		stubPlayer.current.play();
		if (onPlay) {
			onPlay(e);
		}
		let ns = null;
		if (
			(song && playerSong && song.id !== playerSong.id) ||
			playerStatus === PLAYER_STATUS.IDLE ||
			playerStatus === PLAYER_STATUS.PAUSED
		) {
			ns = PLAYER_STATUS.PLAYING;
		} else if (song.id === playerSong.id && playerStatus === PLAYER_STATUS.PLAYING) {
			ns = PLAYER_STATUS.PAUSED;
		}
		props.setPlayerStatus({
			playerStatus: ns,
			playerSong: song,
		});
	};

	let icon = <i className="icon icon-play" />;

	if (song && playerSong && song.id === playerSong.id) {
		if (playerStatus === PLAYER_STATUS.PLAYING) {
			icon = <i className="icon icon-pause" />;
		}
	}
	if (mode === 'single') {
		return (
			<div className="play" onClick={onClick}>
				{icon}

				<audio ref={stubPlayer} src="/audio/stub.mp3" controls={false} />
			</div>
		);
	}

	let progress = 0;
	let songLength = 0;
	let currentTime = 0;

	if (song && playerSong && song.id === playerSong.id) {
		progress = playerProgress;
		songLength = playerSongLength;
		currentTime = playerCurrentTime;
	}

	const onScrub = (e) => {
		console.log('scrub', e);
		const x = e.nativeEvent.offsetX;
		const w = e.nativeEvent.target.clientWidth;
		if (x && w) {
			props.setPlayerPosition(x / w);
		}
	};

	const formatTime = (s) => {
		return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + Math.round(s);
	};

	return (
		<div className="audio-player">
			<div className="play" onClick={onClick}>
				{icon}
			</div>
			<div className="time-and-progress">
				<div className="time">{formatTime(currentTime)}</div>
				<div className="progress-track" onClick={onScrub}>
					<div className="progress-bar" style={{ width: progress + '%' }}></div>
				</div>
				<div className="length">{songLength > 0 && formatTime(songLength)}</div>
			</div>

			<audio ref={stubPlayer} src="/audio/stub.mp3" controls={false} />
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		playerStatus: state.app.playerStatus,
		playerSong: state.app.playerSong,
		playerProgress: state.app.playerProgress,
		playerCurrentTime: state.app.playerCurrentTime,
		playerSongLength: state.app.playerSongLength,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPlayerStatus: (data) => dispatch(setPlayerStatus(data)),
		setPlayerPosition: (value) => dispatch(setPlayerPosition(value)),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AudioPlayerControls));
