import { createSelector } from 'reselect';
import find from 'lodash/find';
import filter from 'lodash/filter';

import { filtered } from '../filters';

const getPlaylists = (state) => state.playlist.playlists;
const getSubmissions = (state) => state.submission.submissions;
const getFilters = (state) => state.playlist.filters;

export const populatePlaylists = createSelector(
	[getPlaylists, getSubmissions, getFilters],
	(playlists, submissions, filters) => {
		const pl = {};
		const sb = Object.assign([], submissions);
		Object.keys(playlists).forEach((p) => {
			let pp = Object.assign({}, playlists[p]);
			let items = [];
			pp.items.forEach((i) => {
				let sub = find(sb, (s) => {
					return s.id === i;
				});
				if (sub) {
					sub.playlist = { id: pp.id, name: pp.name };
					items.push(sub);
				}
			});

			pp.items = items;
			pl[pp.id] = pp;

			const ppLength = pp.items.length;
			const sbScan = filter(sb, (s) => {
				return s.playlist && s.playlist.id === pp.id;
			});
			if (ppLength !== sbScan.length) {
				sbScan.forEach((s) => {
					if (
						!pp.items.find((i) => {
							return i.id === s.id;
						})
					) {
						delete s.playlist;
					}
				});
			}
		});

		return [pl, filtered(sb, filters)];
	}
);
