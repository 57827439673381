import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

import { filtered } from '../filters';

const getSubmissions = (state) => state.submission.submissions;
const getFilters = (state) => state.playlist.filters;
const getPlaylists = (state) => state.playlist.playlists;

export const populateTop100Playlist = createSelector(
	[getSubmissions, getFilters, getPlaylists],
	(submissions, filters, playlists) => {
		let sb = Object.assign([], submissions);
		let allPls = Object.assign({}, playlists);
		let pls = [];

		sb = filter(sb, (s) => {
			return s.grade === 1 || s.starred === 1;
		});

		let pl = filter(sb, (s) => {
			return s.passed === 1;
		});

		pl = orderBy(pl, ['finalOrder'], ['ASC']);
		const stats = {
			credits: {},
			genderRatio: 0,
			genres: {},
			languages: {},
		};

		let names = [];
		let genres = [];
		let languages = [];
		let genderRatio = 0;

		Object.keys(allPls).forEach((p) => {
			let pp = allPls[p];
			console.log({ pp });
			if (pp.round === 3) {
				let items = [];
				pp.items.forEach((i) => {
					let sub = find(sb, (s) => {
						return s.id === i;
					});
					console.log({ sub });
					if (sub) {
						sub.playlist = { id: pp.id, name: pp.name, round: pp.round };
					}

					const idx = findIndex(sb, (ss) => ss.id === sub.id);
					console.log({ idx });
					if (idx > -1) {
						sb[idx] = sub;
					}
					/* if (sub) {
						if (!sub.playlists) {
							sub.playlists = [];
						}
						sub.playlists[pp.day - 1] = { id: pp.id, name: pp.name, round: pp.round };
						if (sub.grades && typeof sub.grades === 'string') {
							console.log('sub', sub.grades);
							sub.grades = JSON.parse(sub.grades);
						}
						items.push(sub);
					} */
				});
				/* pp.items = items;
				pls.push(pp); */
			}
		});

		pl.forEach((p) => {
			sb.splice(sb.indexOf(p), 1);
			let credits = JSON.parse(p.credits);
			Object.keys(credits).forEach((t) => {
				credits[t].forEach((n) => {
					names.push(n.name);
				});
			});
			genderRatio += Number(p.gender_ratio);
			genres.push(p.genre);
			languages.push(p.language);
		});
		names.forEach((n) => {
			if (!stats.credits[n]) {
				stats.credits[n] = { name: n, songs: 0 };
			}
			stats.credits[n].songs++;
		});
		stats.credits = orderBy(stats.credits, ['songs'], ['desc']);
		stats.genderRatio = genderRatio / pl.length;

		genres.forEach((g) => {
			if (!stats.genres[g]) {
				stats.genres[g] = { genre: g, songs: 0 };
			}
			stats.genres[g].songs++;
		});
		stats.genres = orderBy(stats.genres, ['songs'], ['desc']);

		languages.forEach((l) => {
			if (!stats.languages[l]) {
				stats.languages[l] = { language: l, songs: 0 };
			}
			stats.languages[l].songs++;
		});
		stats.languages = orderBy(stats.languages, ['songs'], ['desc']);

		sb = orderBy(sb, ['playlist.name']);
		console.log({ sb });
		return [pl, filtered(sb, filters), stats];
	}
);
