import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';

import app from './app/appReducer';
import user from './user/userReducer';
import submission from './submission/submissionReducer';
import playlist from './playlist/playlistReducer';
import jury from './jury/juryReducer';

import { LOG_OUT } from './user/userActions';

const appReducer = combineReducers({
	app,
	user,
	submission,
	playlist,
	jury,
});

const rootReducer = (state, action) => {
	if (action.type === LOG_OUT) {
		storage.removeItem('persist:vad19');
		state = undefined;
	}
	return appReducer(state, action);
};

const persistConfig = {
	key: 'vad19',
	storage,
	timeout: 10000,
	blacklist: ['app', 'playlist'],
	// whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
	const store = createStore(persistedReducer, applyMiddleware(thunk));
	const persistor = persistStore(store, null);
	return { store, persistor };
};
