import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import SignIn from './SignIn';
import EnterCode from './EnterCode';

class SignInIndex extends Component {
	render() {
		return (
			<>
				<Switch>
					<Route path="/signin/code/" component={EnterCode} />
					<Route path="/signin/" component={SignIn} />
				</Switch>
			</>
		);
	}
}

export default withRouter(SignInIndex);
