/* eslint no-control-regex: 0 */

import React, { useState, useEffect } from 'react';

const EnterCodeView = (props) => {
	if (props.redirect) {
		return null;
	}
	const [code, setCode] = useState('');
	const [done, setDone] = useState(false);

	function onSubmit(e) {
		if (done) {
			e.preventDefault();
			props.sendCode({
				code,
			});
		}
	}

	const goBack = () => {
		props.reset();
		props.history.push('/');
	};

	useEffect(() => {
		const pattern = /(?:[0-9]{6})/;
		if (code.match(pattern)) {
			setDone(true);
		} else {
			setDone(false);
		}
	}, [code]);

	return (
		<div className="view centered sign-in">
			<h1>Logga in</h1>
			<form className="sign-in" onSubmit={onSubmit}>
				<div className="input">
					<label htmlFor="code">Kod från SMS</label>
					<input
						type="text"
						name="code"
						onChange={(e) => {
							setCode(e.currentTarget.value);
						}}
						value={code}
					/>
				</div>
				<div className="input buttons">
					<button type="reset" onClick={goBack}>
						Tillbaka
					</button>
					<button onClick={onSubmit} disabled={done ? false : true}>
						Skicka
					</button>
				</div>
			</form>
		</div>
	);
};

export default EnterCodeView;
