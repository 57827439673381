import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { addUser, getUsers, deleteUser } from '../../data/user/userActions';
import { togglePrompt } from '../../data/app/appActions';

import UsersView from './UsersView';

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.user.user,
		users: state.user.users,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addUser: (data) => dispatch(addUser(data)),
		getUsers: () => dispatch(getUsers()),
		deleteUser: (id) => dispatch(deleteUser(id)),
		togglePrompt: (component) => dispatch(togglePrompt(component)),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersView));
