import React, { useState } from 'react';

const SongListTop = (props) => {
	const { headline, submissions, total, selectedItems, selectItem, setGrades, setStarred } = props;
	const [expanded, setExpanded] = useState(false);

	const doSetGrades = (grade) => {
		setGrades(selectedItems, grade);
	};

	const doSetStarred = (starred) => {
		let selectedObjects = submissions.filter((s) => {
			return selectedItems.indexOf(s.id) > -1;
		});
		let haveStar = selectedObjects.filter((s) => {
			return s.starred === 1;
		});
		console.log(selectedObjects, haveStar);
		if (haveStar.length === selectedObjects.length) {
			setStarred(selectedItems, 0);
		} else {
			setStarred(selectedItems, 1);
		}
	};

	return (
		<div className="songlist-top">
			{headline}
			<span className="count">
				({submissions && submissions.length}/{total} låtar)
			</span>
			<button className="nano" onClick={() => selectItem(false, false, 'all')}>
				{submissions && selectedItems.length > 0 ? <span>Avmarkera alla</span> : <span>Markera alla</span>}
			</button>
			<div
				className={`global-grade hover-button${selectedItems.length === 0 ? ' disabled' : ''}`}
				onMouseOver={() => setExpanded(true)}
				onMouseOut={() => setExpanded(false)}
			>
				<div className="button nano">
					<span className="button-text">Sätt betyg</span>
					<div className={`handle-grades${expanded ? ' expanded' : ''}`}>
						<button className="change-grade" onClick={() => doSetStarred(1)}>
							<i className="icon-star-closed" />
						</button>
						<button className="change-grade" onClick={() => doSetGrades(1)}>
							<i className="icon-thumbsup-closed" />
						</button>
						<button className="change-grade" onClick={() => doSetGrades(-1)}>
							<i className="icon-thumbsdown-closed" />
						</button>
						<button className="change-grade" onClick={() => doSetGrades(0)}>
							<i className="icon-circle-closed" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SongListTop;
