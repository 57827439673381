export const TOGGLE_PROMPT = 'TOGGLE_PROMPT';
export const SET_PLAYER_STATUS = 'SET_PLAYER_STATUS';
export const SET_PLAYER_PROGRESS = 'SET_PLAYER_PROGRESS';
export const SET_JURY_DAY = 'SET_JURY_DAY';
export const SET_PLAYER_POSITION = 'SET_PLAYER_POSITION';

export const togglePrompt = (component) => {
	return {
		type: TOGGLE_PROMPT,
		component,
	};
};

export const setPlayerStatus = (data) => {
	return {
		type: SET_PLAYER_STATUS,
		data,
	};
};

export const setPlayerProgress = (data) => {
	return {
		type: SET_PLAYER_PROGRESS,
		data,
	};
};

export const setPlayerPosition = (data) => {
	return {
		type: SET_PLAYER_POSITION,
		data,
	};
};

export const setJuryDay = (data) => {
	return {
		type: SET_JURY_DAY,
		data,
	};
};
