import React from 'react';
import { DragLayer } from 'react-dnd';

const layerStyles = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 100,
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
};

const DraggableItem = React.memo((props) => {
	if (!props.isDragging) {
		return null;
	}
	const { item, currentOffset, component, clientOffset } = props;

	const multiple = item.items && item.items.length;

	function getItemStyles() {
		if (!currentOffset) {
			return {
				display: 'none',
			};
		}

		let { x, y } = clientOffset;
		const transform = `translate(${x - 110}px, ${y - 20}px)`;
		return {
			transform: transform,
			WebkitTransform: transform,
			width: 220,
		};
	}
	let Component = component;
	return (
		<div style={layerStyles}>
			<div className="drag-preview" style={getItemStyles(props)}>
				{multiple > 1 && <div className="tag">{multiple}</div>}
				<Component data={item.data} selection={[]} />
			</div>
		</div>
	);
});

function collect(monitor) {
	return {
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		currentOffset: monitor.getSourceClientOffset(),
		isDragging: monitor.isDragging(),
		clientOffset: monitor.getClientOffset(),
	};
}

export default DragLayer(collect)(DraggableItem);
