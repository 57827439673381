import React from 'react';
import { DragSource } from 'react-dnd';

const JuryMemberitem = (props) => {
	const { data, disabled } = props;
	const { name } = data;

	if (disabled) {
		return null;
	}

	const { connectDragSource } = props;
	return connectDragSource(
		<div className={'jury-member'}>
			<div className="jury-member-header">
				<span className="name">{name}</span>
			</div>
		</div>
	);
};
export default DragSource(
	'JURYMEMBER',
	{
		beginDrag: (props) => {
			return { item: props.data.id, data: props.data };
		},
	},
	(connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		clientOffset: monitor.getClientOffset(),
		isDragging: monitor.isDragging(),
	})
)(JuryMemberitem);
