import React from 'react';
import ReactSvgPieChart from 'react-svg-piechart';

import { GENRES, LANGUAGES, GENRE_COLORS } from '../../data/constants';

const PlaylistStats = (props) => {
	const { credits, genderRatio, genres, languages } = props.stats;

	let creditEls = [];
	let i = 0;
	credits.forEach((c) => {
		if (i < 5) {
			creditEls.push(
				<div className="stats-item" key={c.name}>
					{c.songs} {c.name}
				</div>
			);
			i++;
		}
	});

	let genreEls = [];
	let genreData = [];
	genres.forEach((g) => {
		genreEls.push(
			<div className="stats-item" key={g.genre}>
				<b style={{ color: GENRE_COLORS[g.genre] }}>&bull;</b> {g.songs} {GENRES[g.genre]}
			</div>
		);
		genreData.push({ title: GENRES[g.genre], value: g.songs, color: GENRE_COLORS[g.genre] });
	});

	let languageEls = [];
	let languageData = [];
	let j = 1;
	languages.forEach((l) => {
		languageEls.push(
			<div className="stats-item" key={l.language}>
				<b style={{ color: GENRE_COLORS[j] }}>&bull;</b> {l.songs} {LANGUAGES[l.language]}
			</div>
		);
		languageData.push({ title: LANGUAGES[l.language], value: l.songs, color: GENRE_COLORS[j++] });
	});

	let maleRatio = Math.round((1 - genderRatio) * 100);
	let femaleRatio = 100 - maleRatio;

	return (
		<div className="stats">
			<div className="stats-section">
				<h4>Låtskrivare</h4>
				{creditEls && creditEls.length > 0 ? (
					<div className="stats-items">{creditEls}</div>
				) : (
					<div className="no-data">Saknar data</div>
				)}
			</div>
			<div className="stats-section">
				<h4>Genrefördelning</h4>

				{genreData && genreData.length > 0 ? (
					<div className="with-chart">
						<ReactSvgPieChart
							data={genreData}
							// If you need expand on hover (or touch) effect
							expandOnHover
							// If you need custom behavior when sector is hovered (or touched)
							onSectorHover={(d, i, e) => {
								if (d) {
									console.log('Mouse enter - Index:', i, 'Data:', d, 'Event:', e);
								} else {
									console.log('Mouse leave - Index:', i, 'Event:', e);
								}
							}}
						/>
						<div className="stats-items">{genreEls}</div>
					</div>
				) : (
					<div className="no-data">Saknar data</div>
				)}
			</div>
			<div className="stats-section">
				<h4>Könsfördelning</h4>
				{maleRatio > -1 && femaleRatio > -1 ? (
					<div className="with-chart">
						<ReactSvgPieChart
							data={[
								{ title: 'Man', value: maleRatio, color: GENRE_COLORS[5] },
								{ title: 'Kvinna', value: femaleRatio, color: GENRE_COLORS[7] },
							]}
							// If you need expand on hover (or touch) effect
							expandOnHover
							// If you need custom behavior when sector is hovered (or touched)
							onSectorHover={(d, i, e) => {
								if (d) {
									console.log('Mouse enter - Index:', i, 'Data:', d, 'Event:', e);
								} else {
									console.log('Mouse leave - Index:', i, 'Event:', e);
								}
							}}
						/>
						<div className="stats-items">
							<div className="stats-item" key="m">
								<b style={{ color: GENRE_COLORS[5] }}>&bull;</b> {maleRatio} % män
							</div>
							<div className="stats-item" key="f">
								<b style={{ color: GENRE_COLORS[7] }}>&bull;</b> {femaleRatio} % kvinnor
							</div>
						</div>
					</div>
				) : (
					<div className="no-data">Saknar data</div>
				)}
			</div>
			<div className="stats-section">
				<h4>Språk</h4>
				{languageData && languageData.length > 0 ? (
					<div className="with-chart">
						<ReactSvgPieChart
							data={languageData}
							// If you need expand on hover (or touch) effect
							expandOnHover
							// If you need custom behavior when sector is hovered (or touched)
							onSectorHover={(d, i, e) => {
								if (d) {
									console.log('Mouse enter - Index:', i, 'Data:', d, 'Event:', e);
								} else {
									console.log('Mouse leave - Index:', i, 'Event:', e);
								}
							}}
						/>
						<div className="stats-items">{languageEls}</div>
					</div>
				) : (
					<div className="no-data">Saknar data</div>
				)}
			</div>
		</div>
	);
};

export default PlaylistStats;
