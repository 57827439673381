import { /* get,  */ find, create } from '../api';
import errorHandler from '../errorHandler';
import { onUpdateSubmissions } from '../submission/submissionActions';

export const ON_JURIES = 'ON_JURIES';
export const ON_JURY = 'ON_JURY';
export const ON_UPDATE_JURY = 'ON_UPDATE_JURY';

const onJuries = (data) => {
	return {
		type: ON_JURIES,
		data,
	};
};

export const getJuries = (params = {}) => {
	return async (dispatch, getCurrentState) => {
		const { user } = getCurrentState().user;
		find('jury', params, user)
			.then((result) => {
				return dispatch(onJuries(result.data.data ? result.data.data : []));
			})
			.catch((error) => {
				return errorHandler(error);
			});
	};
};

const onJury = (juries, day) => {
	return {
		type: ON_JURY,
		data: { juries, day },
	};
};

export const getJury = (day) => {
	return async (dispatch, getCurrentState) => {
		const { user } = getCurrentState().user;
		find('jury', { day }, user)
			.then((result) => {
				return dispatch(onJury(result.data.data, day));
			})
			.catch((error) => {
				return errorHandler(error);
			});
	};
};

export const onUpdateJury = (data) => {
	return {
		type: ON_UPDATE_JURY,
		data,
	};
};

export const createJury = (data) => {
	return async (dispatch, getCurrentState) => {
		const { user } = getCurrentState().user;
		create('jury', data, user)
			.then((result) => {
				return dispatch(onJuries(result.data.data));
			})
			.catch((error) => {
				return errorHandler(error);
			});
	};
};

export const batchJuryGrade = (ids, grade) => {
	return async (dispatch, getCurrentState) => {
		const user = getCurrentState().user.user;
		create('jury-grade/batch/grade', { ids, grade }, user)
			.then((result) => {
				return dispatch(onUpdateSubmissions(result.data.data));
			})
			.catch((error) => {
				errorHandler(error, dispatch);
			});
	};
};
