import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getUsers } from '../../data/user/userActions';
import { getJuries, createJury } from '../../data/jury/juryActions';

import PopulateJuries from './PopulateJuries';

const Juries = (props) => {
	useEffect(() => {
		props.getUsers();
		props.getJuries();
	}, []);

	return (
		<div className="page assign">
			<PopulateJuries {...props} />
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	console.log('stat.jury.juries', state.jury.juries);
	return {
		juries: state.jury.juries,
		users: state.user.users,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getJuries: () => dispatch(getJuries()),
		getUsers: () => dispatch(getUsers()),
		createJury: (data) => dispatch(createJury(data)),
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Juries)
);
