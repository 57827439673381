import React, { useState, useEffect } from 'react';

import DeleteUserPrompt from './DeleteUserPrompt';

const UsersView = (props) => {
	const { togglePrompt, deleteUser } = props;

	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [level, setLevel] = useState(3);
	const [id, setId] = useState(null);
	const [done, setDone] = useState(false);
	const [mode, setMode] = useState('add');

	function onSubmit(e) {
		e.preventDefault();
		if (done) {
			props.addUser({
				id,
				name,
				phone,
				level,
			});
		}
	}

	useEffect(() => {
		props.getUsers();
	}, []);

	useEffect(() => {
		reset();
	}, [props.users]);

	useEffect(() => {
		if (name.length > 3 && level < 3 && phone.length > 9) {
			setDone(true);
		} else if (name.length > 3 && level >= 3) {
			setDone(true);
		} else {
			setDone(false);
		}
	});

	const showUser = (user) => {
		setId(user.id);
		setName(user.name);
		setPhone(user.phone);
		setLevel(user.level);
		setDone(false);
		setMode('edit');
	};

	const reset = (e) => {
		e && e.preventDefault();
		setId(null);
		setName('');
		setPhone('');
		setLevel(3);
		setDone(false);
		setMode('add');
	};

	const onDeleteUser = (e) => {
		e.preventDefault();
		togglePrompt(<DeleteUserPrompt deleteUser={deleteUser} user={{ id, name }} />);
	};

	return (
		<div className="page users middle">
			<div className="page-content half">
				<h1>Användare</h1>
				<div className="users-list">
					{props.users &&
						props.users.map((u) => {
							if (u.level > 0) {
								return (
									<div onClick={() => showUser(u)} key={u.id} className={'level-' + u.level}>
										{u.name}
									</div>
								);
							}
							return null;
						})}
				</div>
			</div>
			<div className="page-content half">
				{mode === 'add' ? <h1>Lägg till ny användare</h1> : <h1>Redigera användare</h1>}
				<form>
					<div className="input">
						<label htmlFor="level">Nivå</label>
						<select name="level" value={level} onChange={(e) => setLevel(e.target.value)}>
							<option value="1">Administratör</option>
							<option value="2">Juryledare</option>
							<option value="3">Jurymedlem</option>
						</select>
					</div>
					<div className="input">
						<label htmlFor="name">Namn</label>
						<input
							type="text"
							id="name"
							name="name"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					{level < 3 && (
						<div className="input">
							<label htmlFor="phone">Telefonnummer</label>
							<input
								type="phone"
								id="phone"
								name="phone"
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
							/>
						</div>
					)}

					<div className="input">
						<label />
						{mode === 'edit' && (
							<button className="small warning" onClick={onDeleteUser}>
								Radera
							</button>
						)}
						{mode === 'edit' && (
							<button className="small" onClick={reset}>
								Avbryt
							</button>
						)}
						<button className="small" onClick={onSubmit} disabled={done ? false : true}>
							Spara
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default UsersView;
