import React, { memo, useState, useRef } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { DropTarget } from 'react-dnd';

import DraggableItem from '../global/DraggableItem';

const SongList = memo((props) => {
	const { submissions, selectedItems, selectItem } = props;
	const [expandedItem, setExpandedItem] = useState(null);

	const listElement = useRef(null);

	const Component = props.component;

	function rowHeight(row) {
		if (expandedItem === row.index) {
			return 290;
		}
		return 48;
	}

	function rowRenderer({
		key, // Unique key within array of rows
		index, // Index of row within collection
		isScrolling, // The List is currently being scrolled
		isVisible, // This row is visible within the List (eg it is not an overscanned row)
		style, // Style object to be applied to row (to position it)
	}) {
		const item = submissions[index];
		const isSelected = selectedItems.indexOf(item.id) > -1;
		return (
			<Component
				selectItem={selectItem}
				setExpanded={expandItem}
				expanded={expandedItem === index}
				index={index}
				selected={isSelected}
				data={item}
				key={key}
				virtualStyle={style}
				selection={selectedItems}
				day={props.day}
			/>
		);
	}

	function expandItem(e, index) {
		e.stopPropagation();
		setExpandedItem(index);
		listElement.current.recomputeRowHeights();
		listElement.current.forceUpdateGrid();
	}
	const { connectDropTarget } = props;
	return connectDropTarget(
		<div className="song-list">
			<AutoSizer>
				{({ height, width }) => (
					<List
						ref={listElement}
						rowCount={submissions.length}
						height={height}
						width={width}
						rowHeight={(index) => rowHeight(index)}
						rowRenderer={rowRenderer}
						className="v-list"
					/>
				)}
			</AutoSizer>
			<DraggableItem component={Component} />
		</div>
	);
});

export default DropTarget(
	'SONG',
	{
		drop(props, monitor) {
			props.onDrop(monitor.getItem().items, null, monitor.getItem().inPlaylist);
		},
	},
	(connect, monitor) => ({
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		canDrop: monitor.canDrop(),
	})
)(SongList);
